import React from 'react'
import Header from '../headerone/Main'
import Bredcom from '../Bredcom/Main'
import Form from '../menadmission/Form' 
import Image from "../Image/Main";

function Main() {
  return (
    <>
        <Header/>
        <Bredcom 
        title="Accueil" 
        subtitle="Mobilité internationale"/>
        <section className="about-area about-p pt-120 pb-120 p-relative fix">
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="s-about-img p-relative  wow fadeInLeft animated" data-animation="fadeInLeft" data-delay=".4s">
                  <Image name="ImageMobiliteInternationale" />
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s">
                  <div className="about-title second-title pb-25">
                    <h2>MOBILITÉ INTERNATIONALE</h2>
                  </div>
                  <p className="txt-clr">
                    Notre programme de mobilité internationale s’articule autour de deux axes : <strong>la récompenses des meilleurs de nos étudiants sur chaque parcours</strong> et <strong>un séjour professionnel à l'étranger</strong> après l’obtention des diplômes. <br /> <br />
                    Il s’agit d’offrir l’une des meilleures expériences professionnelles et touristiques à chaque étudiant, dans les meilleures entreprises des pays d’implantation de notre académie, soit en Afrique, soit en Europe.
                     <br /> <br />
                    Côtoyer les réalités professionnelles des meilleures entreprises du secteurs des métiers étudiés pour parachever un parcours d’excellence avec à la clé, un séjours touristique inoubliable.
                  </p>
                  <h3>OBJECTIFS</h3>
                  <ul className="pr-ul">
                    <li className="w100">
                        <div className="icon">
                            <i className="fal fa-check" />
                        </div>
                        <div className="text mt-0">
                          Faciliter la concrétisation des projets de formation des étudiants de Skills Bridge Academy, au sein de notre réseau d’enseignement supérieur afin de lui conférer une renommée internationale.
                        </div>
                    </li>
                    <li className="w100">
                        <div className="icon">
                            <i className="fal fa-check" />
                        </div>
                        <div className="text mt-0">
                          Valoriser les parcours d'excellence des étudiants au sein de notre réseau d’établissements.
                        </div>
                    </li>
                  </ul>
                  <h3>PRIX</h3>
                  <ul className="pr-ul">
                    <li className="w100">
                        <div className="icon">
                            <i className="fal fa-check" />
                        </div>
                        <div className="text mt-0">
                          Un séjour Professionnel et Touristique dans un pays au choix de la direction de l’école.
                        </div>
                    </li>
                    <li className="w100">
                        <div className="icon">
                            <i className="fal fa-check" />
                        </div>
                        <div className="text mt-0">
                          Une enveloppe de 3000 € pour des courses personnelles lors du séjour (Ou pas).
                        </div>
                    </li>
                    <li className="w100">
                        <div className="icon">
                            <i className="fal fa-check" />
                        </div>
                        <div className="text mt-0">
                          Un stage d’un mois sanctionné d’un certificat, dans une entreprise partenaire à l’étranger pour consolider les acquis professionnels.
                        </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
            className="steps-area2 p-relative fix"
            style={{ backgroundColor: "#032e3f" }}
          >
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6 col-md-12">
                  <div
                    className="step-box step-box3 wow fadeInUp animated"
                    data-animation="fadeInUp"
                    data-delay=".4s"
                  >
                    <div className="text project-detail mt-0">
                      <h2>Bénéficiaires</h2>
                      <p className="text-white">
                        Les candidats doivent répondre aux quatre critères suivants :
                      </p>
                      <ul className="pr-ul text-white">
                        <li className="w100">
                            <div className="icon">
                                <i className="fal fa-check" />
                            </div>
                            <div className="text mt-0">
                              Avoir obtenu 18/20 de moyenne, régulièrement sur les 3 blocs de l’année académique, et 16/20 au minimum dans toutes les matières professionnelles de son parcours. L'éligibilité se base sur la dernière publication de ces classements à la date de dépôt.
                            </div>
                        </li>
                        <li className="w100">
                            <div className="icon">
                                <i className="fal fa-check" />
                            </div>
                            <div className="text mt-0">
                              Être domicilié dans le pays d’implantation de l’école les deux années précédant le dépôt de dossier (avis d'imposition de l'année N-1, N-2 et certificat de résidence).
                            </div>
                        </li>
                        <li className="w100">
                            <div className="icon">
                                <i className="fal fa-check" />
                            </div>
                            <div className="text mt-0">
                              Avoir obtenu le baccalauréat dans l’une des écoles du pays de résidence.
                            </div>
                        </li>
                        <li className="w100">
                            <div className="icon">
                                <i className="fal fa-check" />
                            </div>
                            <div className="text mt-0">
                              Être en fin de parcours (Bachelor 3 ou Master 2)
                            </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div
                    className=" wow fadeInRight animated"
                    data-animation="fadeInRight"
                    data-delay=".4s"
                  >
                    <Image name="ImageEtudianteBrillante" />
                  </div>
                </div>
              </div>
            </div>
        </section>
        <section className="about-area about-p pt-120 pb-120 p-relative fix">
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="s-about-img p-relative  wow fadeInLeft animated" data-animation="fadeInLeft" data-delay=".4s">
                  <Image name="ImageCVDossier" />
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s">
                  <div className="about-title second-title pb-25">
                    <h2>Instruction des dossiers</h2>
                  </div>
                  <p className="txt-clr">
                    Le dossier de candidature est téléchargeable ici- (Créer un lien de téléchargement) en début d’année.<br /> <br />
                    Le dossier de candidature à la mobilité internationale comporte notamment les demandes d'informations suivantes :
                  </p>
                  <ul className="pr-ul">
                    <li className="w100">
                        <div className="icon">
                            <i className="fal fa-check" />
                        </div>
                        <div className="text mt-0">
                          L'établissement d'inscription à l'international et le niveau d'études
                        </div>
                    </li>
                    <li className="w100">
                        <div className="icon">
                            <i className="fal fa-check" />
                        </div>
                        <div className="text mt-0">
                          Le curriculum vitae
                        </div>
                    </li>
                    <li className="w100">
                        <div className="icon">
                            <i className="fal fa-check" />
                        </div>
                        <div className="text mt-0">
                          Le projet professionnel
                        </div>
                    </li>
                  </ul>
                  <p>
                    Un comité de sélection des candidatures est organisé par les services pédagogiques de l’école. Il sélectionne les meilleures candidatures en fonction de la renommée des entreprises d'accueil, de l'excellence académique des candidats et de la qualité de leurs projets professionnels.<br /> <br />
                    Le comité de sélection est composé de 6 membres sur proposition conjointe du Président de l’académie, du directeur pédagogique et des représentants des entreprises partenaires.<br /> <br />
                    Le nombre de destination d’accueil est déterminé chaque année en fonction du budget d attribué au dispositif par l’école et ses partenaires.<br /> <br />
                    Les candidats seront informés quand leur dossier sera examiné par le comité. En fonction du nombre de candidatures reçues, le comité sera convoqué 2 fois au cours de l'année universitaire.
                  </p>
                  <h3>Bon à savoir</h3>
                  <ul className="pr-ul">
                    <li className="w100">
                        <div className="icon">
                            <i className="fal fa-check" />
                        </div>
                        <div className="text mt-0">
                          L’organisation des voyages et les séjours sont entièrement pris en charge par SKILLS BRIDGE ACADEMY.
                        </div>
                    </li>
                    <li className="w100">
                        <div className="icon">
                            <i className="fal fa-check" />
                        </div>
                        <div className="text mt-0">
                          Les lauréats sont exemptés des frais de Visas et des démarches administratives y afférentes.
                        </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
    </>
  )
}

export default Main