import React from 'react'
import Bredcom from '../Bredcom/Main'
import Header from '../headerone/Main'
import First from '../mencourses/First'
import Brand from '../menhomethree/Brand'
import Courses from '../menhome/Courses'
import { Link } from 'react-router-dom'
import Image from '../Image/Main'


function Main() {
  return (
    <>
        <Header/>
        <Bredcom 
        subtitle="Masters"/>
        <div class="container">
          <h1 className="mt-50">Nos formations en Master</h1>
          <div className="row mt-50">
            <div className="col-lg-4 col-md-6 ">
              <div className="courses-item mb-30 hover-zoomin">
                  <div className="thumb fix">
                      <Link to="/courses/master/rh-et-management-du-capital-humain">
                          <Image name="ImageCourseRH" classname="radius2em" />
                      </Link>
                  </div>
                  <div className="courses-content">
                      <h3>
                      <Link to="/courses/master/rh-et-management-du-capital-humain"> RH et Management du capital humain</Link>
                      </h3>
                      <p>
                      Le Parcours RH et Management du Capital Humain est conçu pour former des professionnels capables de transformer le potentiel humain en avantage concurrentiel.
                      </p>
                      <Link to="/courses/master/rh-et-management-du-capital-humain" className="readmore">
                      + d'infos <i className="fal fa-long-arrow-right" />
                      </Link>
                  </div>
                  <div className="icon">
                      <Image name="ImageCouIcon" />
                  </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 ">
              <div className="courses-item mb-30 hover-zoomin">
                <div className="thumb fix">
                    <Link to="/courses/master/management-des-organisations-et-des-unites-operationnelles">
                        <Image name="ImageCourseManagement" classname="radius2em" />
                    </Link>
                </div>
                <div className="courses-content">
                    <h3>
                    <Link to="/courses/master/management-des-organisations-et-des-unites-operationnelles"> Management des Organisation et des unités opérationnelles</Link>
                    </h3>
                    <p>
                    Notre parcours en Management des Organisations et des Unités Opérationnelles est conçu pour les futurs professionnels souhaitant maîtriser les rouages de la gestion d’équipe et des opérations.
                    </p>
                    <Link to="/courses/master/management-des-organisations-et-des-unites-operationnelles" className="readmore">
                    + d'infos <i className="fal fa-long-arrow-right" />
                    </Link>
                </div>
                <div className="icon">
                    <Image name="ImageCouIcon" />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 ">
              <div className="courses-item mb-30 hover-zoomin">
                <div className="thumb fix">
                    <Link to="/courses/master/commerce-marketing-et-communication">
                        <Image name="ImageCourseCommerce" classname="radius2em" />
                    </Link>
                </div>
                <div className="courses-content">
                    <h3>
                    <Link to="/courses/master/commerce-marketing-et-communication"> Commerce Marketing et Communication</Link>
                    </h3>
                    <p>
                    Le Parcours Commerce, Marketing et Communication forme des professionnels aptes à la conception et à l'exécution de stratégies commerciales et marketing percutantes.
                    </p>
                    <Link to="/courses/master/commerce-marketing-et-communication" className="readmore">
                    + d'infos <i className="fal fa-long-arrow-right" />
                    </Link>
                </div>
                <div className="icon">
                    <Image name="ImageCouIcon" />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 ">
              <div className="courses-item mb-30 hover-zoomin">
                <div className="thumb fix">
                    <Link to="/courses/master/management-de-la-qualite-qhse">
                        <Image name="ImageCourseQhse" classname="radius2em" />
                    </Link>
                </div>
                <div className="courses-content">
                    <h3>
                    <Link to="/courses/master/management-de-la-qualite-qhse"> Management de la qualité QHSE </Link>
                    </h3>
                    <p>
                    Le Parcours Management de la Qualité QHSE est spécialement conçu pour former des experts capables de mettre en place et de gérer les systèmes QHSE et des politiques de durabilité au sein des entreprises.
                    </p>
                    <Link to="/courses/master/management-de-la-qualite-qhse" className="readmore">
                    + d'infos <i className="fal fa-long-arrow-right" />
                    </Link>
                </div>
                <div className="icon">
                    <Image name="ImageCouIcon" />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 ">
              <div className="courses-item mb-30 hover-zoomin">
                <div className="thumb fix">
                    <Link to="/courses/master/banque-assurance-et-fintech">
                        <Image name="ImageCourseBanque" classname="radius2em" />
                    </Link>
                </div>
                <div className="courses-content">
                    <h3>
                    <Link to="/courses/master/banque-assurance-et-fintech"> Banque Assurance et Fintech</Link>
                    </h3>
                    <p>
                    Le Parcours Banque, Assurance et Fintech offre une formation de pointe pour comprendre et intégrer les innovations qui transforment le secteur financier.
                    </p>
                    <Link to="/courses/master/banque-assurance-et-fintech" className="readmore">
                    + d'infos <i className="fal fa-long-arrow-right" />
                    </Link>
                </div>
                <div className="icon">
                    <Image name="ImageCouIcon" />
                </div>
              </div>
            </div>
          </div>
        </div>
      <Brand/>
    </>
  )
}

export default Main