import React from "react";
import Slider from "react-slick";
import Image from "../Image/Main";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button
      type="button"
      className={`slick-next ${className}`}
      style={{ ...style }}
      onClick={onClick}
    >
      <i className="fal fa-arrow-down"></i>
    </button>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button
      type="button"
      className={`slick-prev ${className}`}
      style={{ ...style }}
      onClick={onClick}
    >
      <i className="fal fa-arrow-up"></i>
    </button>
  );
}

function Brand() {
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 1500,
    arrows: false,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div
        className="brand-area pt-60 pb-60"
        style={{ backgroundColor: "#da324b" }}
      >
        <div className="container">
          <Slider className="row brand-active" {...settings}>
            <div className="col-xl-2">
              <div className="single-brand ml-5">
                <Image name="ImageBrandLogoBeniking" />
              </div>
            </div>
            <div className="col-xl-2">
              <div className="single-brand ml-5">
                <Image name="ImageBrandLogoPolygloth" />
              </div>
            </div>
            <div className="col-xl-2">
              <div className="single-brand ml-5">
                <Image name="ImageBrandLogoQualiopi" />
              </div>
            </div>
            <div className="col-xl-2">
              <div className="single-brand ml-5">
                <Image name="ImageBrandLogoFdfp" />
              </div>
            </div>
            <div className="col-xl-2">
              <div className="single-brand ml-5">
                <Image name="ImageBrandLogoCames" />
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </>
  );
}

export default Brand;
