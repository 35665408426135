import React, { useEffect, useState } from "react";
import zxcvbn from "zxcvbn";
function PasswordStrengthMeter({ password }) {
  const [score, setScore] = useState(0);
  const [scoreText, setScoreText] = useState("");
  const [background, setBackground] = useState("");
  const progress = (score * 100) / 4;
  const handleScore = (color, msg) => {
    setBackground(color);
    setScoreText(msg);
  };
  useEffect(() => {
    if (password !== "") {
      setScore(zxcvbn(password).score);
    }
  }, [password]);
  useEffect(() => {
    switch (score) {
      case 2:
        handleScore("yellow", "Peut être renforcé");
        break;
      case 3:
        handleScore("orange", "Mot de passe robuste");
        break;
      case 4:
        handleScore("green", "Mot de passe très robuste");
        break;
      default:
        handleScore("red", "Trop Faible");
        break;
    }
  }, [score]);
  return (
    <>
      <div
        className="row mb-30"
        style={{ visibility: password !== "" ? "visible" : "hidden" }}
      >
        <div className="col-lg-7">
          <div className="cprogress">
            <div
              className="cprogress-bar"
              style={{
                width: `${progress}%`,
                background: `${background}`,
              }}
            />
          </div>
        </div>
        <div id="pwd-strength-info" className="col-lg-5 text-left">
          {scoreText}
        </div>
      </div>
    </>
  );
}

export default PasswordStrengthMeter;
