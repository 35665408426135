import React from 'react'
import Bredcom from '../Bredcom/Main'
import Header from '../headerone/Main'
import MasterBaf from '../mencoursedetails/MasterBaf'
import Courses from '../menhome/Courses'
import Brand from '../menhomethree/Brand'

function Main() {
  return (
    <>
        <Header/>
        <Bredcom 
          title="Masters" 
          titleLink="/courses/masters" 
          subtitle="Master - Banque Assurance et Fintech" />
        <MasterBaf/>
        <Courses/>
    </>
  )
}

export default Main