import React, { useState } from "react";
import { Link } from "react-router-dom";
import Image from "../Image/Main";

function Main() {
  const [Btnshow, setBtnshow] = useState(false);
  const [mobile, setmobile] = useState(false);
  const [Events, setEvent] = useState(false);
  const [News, setNews] = useState(false);
  const [Services, setServices] = useState(false);
  const [Blog, setblog] = useState(false);

  return (
    <>
      <header className="header-area header">
        <div className="header-top second-header d-none d-md-block">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-4 col-md-4 d-none d-lg-block ">
                <div className="header-social">
                  <span>
                    Suivez-nous
                    <a href="#" title="Facebook">
                      <i className="fab fa-facebook-f" />
                    </a>
                    <a href="#" title="LinkedIn">
                      <i className="fab fa-instagram" />
                    </a>
                    <a href="#" title="Twitter">
                      <i className="fab fa-twitter" />
                    </a>
                    <a href="#" title="Twitter">
                      <i className="fab fa-youtube" />
                    </a>
                  </span>
                </div>
              </div>
              <div className="col-lg-8 col-md-8 d-none d-lg-block text-right">
                <div className="header-cta">
                  <ul>
                    <li>
                      <div className="call-box">
                        <div className="icon">
                          <Image name="ImagePhoneCall" />
                        </div>
                        <div className="text">
                          <span>Call Now !</span>
                          <strong>
                            <a href="tel:+917052101786">+91 7052 101 786</a>
                          </strong>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="call-box">
                        <div className="icon">
                          <Image name="ImageMailing" />
                        </div>
                        <div className="text">
                          <span>Email Now</span>
                          <strong>
                            <a href="mailto:info@example.com">
                              {" "}
                              info@example.com
                            </a>
                          </strong>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="header-sticky" className="menu-area">
          <div className="container">
            <div className="second-menu">
              <div className="row align-items-center">
                <div className="col-xl-3 col-lg-3">
                  <div className="logo">
                    <a href="/">
                      <Image name="Logo" />
                    </a>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6">
                  <div className="main-menu text-right text-xl-right">
                    <nav id="mobile-menu">
                      <ul>
                        <li className="has-sub">
                          <a href="/">Home</a>
                          <ul>
                            <li>
                              <a href="/">University</a>
                            </li>
                            <li>
                              <a href="/home-two">Kindergarten</a>
                            </li>
                            <li>
                              <a href="/home-three">High School</a>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <Link to="/about">About Us</Link>
                        </li>
                        <li className="has-sub">
                          <a href="/courses">Courses</a>
                          <ul>
                            <li>
                              <a href="/courses">Courses</a>
                            </li>
                            <li>
                              {" "}
                              <a href="/courses-2">Courses 02</a>
                            </li>
                            <li>
                              {" "}
                              <a href="/single-courses">Course Details</a>
                            </li>
                            <li>
                              {" "}
                              <a href="/single-courses-2">Course Details 02</a>
                            </li>
                          </ul>
                        </li>
                        <li className="has-sub">
                          <a href="#">Pages</a>
                          <ul>
                            <li>
                              <a href="/event">Event</a>
                            </li>
                            <li>
                              <a href="/single-event">Event Details</a>
                            </li>
                            <li>
                              <a href="/projects">Gallery</a>
                            </li>
                            <li>
                              <a href="/pricing">Pricing</a>
                            </li>
                            <li>
                              <a href="/faq">Faq</a>
                            </li>
                            <li>
                              <a href="/team">Teacher</a>
                            </li>
                            <li>
                              <a href="/team-single">Teacher Details</a>
                            </li>
                            <li>
                              <a href="/404-error">404 Error</a>
                            </li>
                          </ul>
                        </li>
                        <li className="has-sub">
                          <a href="/blog">Blog</a>
                          <ul>
                            <li>
                              <a href="/blog">Blog</a>
                            </li>
                            <li>
                              <a href="/blog-details">Blog Details</a>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <a href="/contact">Contact</a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 text-right d-none d-lg-block text-right text-xl-right">
                  <div className="login">
                    <ul>
                      <li>
                        <Link
                          to="#"
                          className="menu-tigger"
                          onClick={() => {
                            setBtnshow(true);
                          }}
                        >
                          <i
                            className="fal fa-search"
                            onClick={() => {
                              setBtnshow(true);
                            }}
                          />
                        </Link>
                      </li>
                      <li>
                        <div className="second-header-btn">
                          <a href="/login" className="btn">
                            Se connecter
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-12">
                  <div className="mobile-menu mean-container">
                    <div className="mean-bar">
                      <a
                        href="#nav"
                        onClick={() => setmobile(!mobile)}
                        className={`meanmenu-reveal ${mobile && "meanclose"}`}
                        style={{
                          right: 0,
                          left: "auto",
                          textAlign: "center",
                          textIndent: 0,
                          fontSize: 18,
                        }}
                      >
                        {mobile ? (
                          "X"
                        ) : (
                          <span>
                            <span>
                              <span></span>
                            </span>
                          </span>
                        )}
                      </a>
                      {mobile && (
                        <nav className="mean-nav">
                          <ul style={{ display: "block" }}>
                            <li className="has-sub">
                              <a href="/">Home</a>
                              {Events && (
                                <ul style={{ display: "block" }}>
                                  <li>
                                    <Link to="/">University</Link>
                                  </li>
                                  <li>
                                    <Link to="/home-two">Kindergarten</Link>
                                  </li>
                                  <li>
                                    <Link to="/home-three">High School</Link>
                                  </li>
                                </ul>
                              )}
                              <a
                                className={`mean-expand ${
                                  mobile && "mean-clicked"
                                }`}
                                onClick={() => {
                                  setEvent(!Events);
                                }}
                                href="#"
                                style={{ fontSize: 18 }}
                              >
                                {Events ? "-" : "+"}
                              </a>
                            </li>
                            <li>
                              <Link to="/about">About Us</Link>
                            </li>
                            <li className="has-sub">
                              <Link to="/courses">Courses</Link>
                              {News && (
                                <ul style={{ display: "block" }}>
                                  <li>
                                    <Link to="/courses">Courses</Link>
                                  </li>
                                  <li>
                                    {" "}
                                    <Link to="/courses-2">Courses 02</Link>
                                  </li>
                                  <li>
                                    {" "}
                                    <Link to="/single-courses">
                                      Course Details
                                    </Link>
                                  </li>
                                  <li>
                                    {" "}
                                    <Link to="/single-courses-2">
                                      Course Details 02
                                    </Link>
                                  </li>
                                </ul>
                              )}

                              <a
                                className={`mean-expand ${
                                  mobile && "mean-clicked"
                                }`}
                                onClick={() => {
                                  setNews(!News);
                                }}
                                href="#"
                                style={{ fontSize: 18 }}
                              >
                                {News ? "-" : "+"}
                              </a>
                            </li>
                            <li className="has-sub">
                              <a href="#">Pages</a>
                              {Services && (
                                <ul style={{ display: "block" }}>
                                  <li>
                                    <Link to="/event">Event</Link>
                                  </li>
                                  <li>
                                    <Link to="/single-event">
                                      Event Details
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/projects">Gallery</Link>
                                  </li>
                                  <li>
                                    <Link to="/pricing">Pricing</Link>
                                  </li>
                                  <li>
                                    <Link to="/faq">Faq</Link>
                                  </li>
                                  <li>
                                    <Link to="/team">Teacher</Link>
                                  </li>
                                  <li>
                                    <Link to="/team-single">
                                      Teacher Details
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/404-error">404 Error</Link>
                                  </li>
                                </ul>
                              )}
                              <a
                                className={`mean-expand ${
                                  mobile && "mean-clicked"
                                }`}
                                onClick={() => {
                                  setServices(!Services);
                                }}
                                href="#"
                                style={{ fontSize: 18 }}
                              >
                                {Services ? "-" : "+"}
                              </a>
                            </li>
                            <li className="has-sub">
                              <Link to="/blog">Blog</Link>
                              {Blog && (
                                <ul style={{ display: "block" }}>
                                  <li>
                                    <Link to="/blog">Blog</Link>
                                  </li>
                                  <li>
                                    <Link to="/blog-details">Blog Details</Link>
                                  </li>
                                </ul>
                              )}
                              <a
                                className={`mean-expand ${
                                  mobile && "mean-clicked"
                                }`}
                                onClick={() => {
                                  setblog(!Blog);
                                }}
                                href="#"
                                style={{ fontSize: 18 }}
                              >
                                {Blog ? "-" : "+"}
                              </a>
                            </li>
                            <li className="mean-last">
                              <Link to="/contact">Contact</Link>
                            </li>
                          </ul>
                        </nav>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      {Btnshow && (
        <>
          <div className={`offcanvas-menu ${"active"}`}>
            <span className="menu-close" onClick={() => setBtnshow(false)}>
              <i className="fas fa-times" onClick={() => setBtnshow(false)} />
            </span>
            <form
              role="search"
              method="get"
              id="searchform"
              className="searchform"
              action="http://wordpress.zcube.in/xconsulta/"
            >
              <input type="text" name="s" id="search" placeholder="Search" />
              <button>
                <i className="fa fa-search" />
              </button>
            </form>
            <div id="cssmenu3" className="menu-one-page-menu-container">
              <ul className="menu">
                <li className="menu-item menu-item-type-custom menu-item-object-custom">
                  <Link to="/">Home</Link>
                </li>
                <li className="menu-item menu-item-type-custom menu-item-object-custom">
                  <Link to="/about">About Us</Link>
                </li>
                <li className="menu-item menu-item-type-custom menu-item-object-custom">
                  <Link to="/courses">Courses</Link>
                </li>
                <li className="menu-item menu-item-type-custom menu-item-object-custom">
                  <Link to="/pricing">Pricing </Link>
                </li>
                <li className="menu-item menu-item-type-custom menu-item-object-custom">
                  <Link to="/team">Teacher</Link>
                </li>
                <li className="menu-item menu-item-type-custom menu-item-object-custom">
                  <Link to="/projects">Gallery</Link>
                </li>
                <li className="menu-item menu-item-type-custom menu-item-object-custom">
                  <Link to="/blog">Blog</Link>
                </li>
                <li className="menu-item menu-item-type-custom menu-item-object-custom">
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>
            </div>
            <div id="cssmenu2" className="menu-one-page-menu-container">
              <ul id="menu-one-page-menu-12" className="menu">
                <li className="menu-item menu-item-type-custom menu-item-object-custom">
                  <Link to="#home">
                    <span>+8 12 3456897</span>
                  </Link>
                </li>
                <li className="menu-item menu-item-type-custom menu-item-object-custom">
                  <Link to="#howitwork">
                    <span>info@example.com</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className={`offcanvas-overly ${"active"}`}> </div>
        </>
      )}
    </>
  );
}

export default Main;
