import React from 'react'
import Bredcom from '../Bredcom/Main'
import Header from '../headerone/Main'
import BachelorQhse from '../mencoursedetails/BachelorQhse'
import Courses from '../menhome/Courses'
import Brand from '../menhomethree/Brand'

function Main() {
  return (
    <>
        <Header/>
        <Bredcom 
          title="Bachelors" 
          titleLink="/courses/bachelors" 
          subtitle="Bachelor - Responsable  Qualité et QHSE" />
        <BachelorQhse/>
        <Courses/>
    </>
  )
}

export default Main