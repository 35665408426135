import React from "react";
import RegisterForm from "../authentication/RegisterForm";
import Header from "../headerone/Main";

function Main() {
  return (
    <>
      <Header />
      <RegisterForm />
    </>
  );
}

export default Main;
