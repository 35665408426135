import {React, useState} from 'react'
import Image from '../Image/Main'

function Touch() {
    const [country, setCountry] = useState("ci")

  return (
    <>
        <section id="services" className="services-area pt-120 pb-90 fix">
            <div className="container">
                <div className="row">
                <div className="col-lg-12">
                    <div className="section-title mb-50 wow fadeInDown animated" data-animation="fadeInDown" data-delay=".4s" >
                        <h5>
                            <i className="fal fa-graduation-cap" /> Nous contacter
                        </h5>
                        <div className='row'>
                            <h2 className='col-10'>Nous sommes disponibles pour vous</h2>  
                            <div className='col-2'>
                                <select value={country} onChange={(e) => setCountry(e.target.value)}>
                                    <option value="ci">Côte d'Ivoire</option>
                                    <option value="fr">France</option>
                                    <option value="sn">Sénégal</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <div className="row">
                <div className="col-lg-4 col-md-4">
                    <div className="services-box text-center">
                    <div className="services-icon">
                        <Image name="ImageContactIcon1" />
                    </div>
                    <div className="services-content2">
                        <strong className='text-black'>
                            {
                                (country == "fr") ? 
                                <>
                                    <a href="tel:+33189162145">+33 1 89 16 21 45</a> <br />
                                    <a href="tel:+33610105911">+33 6 10 10 59 11</a>
                                </>
                                : 
                                (country == "ci") ?
                                <a href="tel:+2250720201707">+225 07 20 20 17 07</a>
                                :
                                <a href="tel:+221000000000">+221 0 00 00 00 00</a>
                            }
                        </strong>
                        <p>Téléphone</p>
                    </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4">
                    <div className="services-box text-center">
                    <div className="services-icon">
                        <Image name="ImageContactIcon2" />
                    </div>
                    <div className="services-content2">
                        <strong className='text-black'>
                        <a href="mailto:contact@skillsbridge-academy.com">
                            contact@skillsbridge-academy.com
                        </a>
                        </strong>
                        <p>Adresse email</p>
                    </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4">
                    <div className="services-box text-center">
                    <div className="services-icon">
                        <Image name="ImageContactIcon3" />
                    </div>
                    <div className="services-content2">
                        <strong className='text-black'>
                            {
                                (country == "fr") ? 
                                <>Tour Trinity, 1 bis Place de La Défense, 92400 Courbevoie, France</>
                                : 
                                (country == "ci") ?
                                <>Abidjan, Cocody, Riviera Palmeraie. Lot 108, Non loin du collège Saint Viateur</>
                                :
                                <>Dakar</>
                            }
                               
                        </strong>
                        <p>Bureau</p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="map fix mt-5" style={{ background: "#f5f5f5" }}>
                <div className="container-flud">
                    <div className="row">
                    <div className="col-lg-12">
                        {
                            (country == "fr") ? 
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2623.2479185969596!2d2.2382400760023162!3d48.89161219836863!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e665269ef3302f%3A0xccc1141b97d47181!2s1%20bis%20Pl.%20de%20la%20D%C3%A9fense%2C%2092400%20Courbevoie!5e0!3m2!1sfr!2sfr!4v1727452903070!5m2!1sfr!2sfr" width={600} height={450} style={{ border: 0 }} allowFullScreen="" loading="lazy" />
                            : 
                            (country == "ci") ?
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3972.2231696907284!2d-3.961687124034062!3d5.3829122214587235!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfc19358354fce59%3A0xfc4c4eaa18ee261a!2sSaint%20viateur!5e0!3m2!1sfr!2sfr!4v1730505523124!5m2!1sfr!2sfr" width={600} height={450} style={{ border: 0 }} allowFullScreen="" loading="lazy" />
                            :
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d123488.80135225732!2d-17.548227310815047!3d14.71117593952092!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xec172f5b3c5bb71%3A0xb17c17d92d5db21f!2zRGFrYXIsIFPDqW7DqWdhbA!5e0!3m2!1sfr!2sfr!4v1730505806858!5m2!1sfr!2sfr" width={600} height={450} style={{ border: 0 }} allowFullScreen="" loading="lazy" />
                        }
                        
                    </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default Touch