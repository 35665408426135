import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import About from "../component/About/Main";
import Admission from "../component/Admission/Main";
import PaymentSuccess from "../component/Admission/PaymentSuccess";
import PaymentCancel from "../component/Admission/PaymentCancel";
import Alternance from "../component/Alternance/Main";
import Diagnostic from "../component/Diagnostic/Main";
import MobiliteInternationale from "../component/MobiliteInternationale/Main";
import IngenieriePedagogique from "../component/IngenieriePedagogique/Main";
import Prosfibe from "../component/Prosfibe/Main";
import Blog from "../component/Blog/Main";
import Blogdeatils from "../component/Blogdeatils/Main";
import Contact from "../component/contact/Main";
import Coursesdeatils from "../component/coursedetails/Main";
import Coursesdeatilstwo from "../component/coursedetailstwo/Main";
import Courses from "../component/Courses/Main";
import Bachelors from "../component/Courses/Bachelors";
import Masters from "../component/Courses/Masters";
import Coursestwo from "../component/coursestwo/Main";
import Footer from "../component/footer/Main";
import Home from "../component/home/Main";
import Homethree from "../component/homethree/Main";
import Hometwo from "../component/hometwo/Main";
import Login from "../component/Login/Main";
import Error from "../component/Pages/error/Main";
import Event from "../component/Pages/event/Main";
import Eventdeatils from "../component/Pages/eventdeatils/Main";
import Faq from "../component/Pages/Faq/Main";
import Gallery from "../component/Pages/gallery/Main";
import Pricing from "../component/Pages/Pricing/Main";
import Projectdeatils from "../component/Pages/Projectdeatils/Main";
import Teacher from "../component/Pages/Teacher/Main";
import Teacherdeatils from "../component/Pages/teacherdetails/Main";
import Passwordreset from "../component/Passwordreset/Main";
import Register from "../component/Register/Main";
import MasterRH from "../component/coursedetails/MasterRH";
import MasterBaf from "../component/coursedetails/MasterBaf";
import MasterQhse from "../component/coursedetails/MasterQhse";
import MasterMouo from "../component/coursedetails/MasterMouo";
import MasterComMarkCo from "../component/coursedetails/MasterComMarkCo";
import BachelorRH from "../component/coursedetails/BachelorRH";
import BachelorBaf from "../component/coursedetails/BachelorBaf";
import BachelorQhse from "../component/coursedetails/BachelorQhse";
import BachelorComMarkCo from "../component/coursedetails/BachelorComMarkCo";
import BachelorCpuo from "../component/coursedetails/BachelorCpuo";
import Tarifs from "../component/Courses/Tarifs";

function Index() {
  const location = useLocation();
  const path = location.pathname;
  useEffect(() => {
    window.scroll(0, 0);
  }, [path]);

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about/ingenierie-pedagogique" element={<IngenieriePedagogique />} />
        <Route path="/mobilite-internationale" element={<MobiliteInternationale />} />
        <Route path="/alternance" element={<Alternance />} />
        <Route path="/about/diagnostic" element={<Diagnostic />} />
        <Route path="/prosfibe" element={<Prosfibe />} />
        <Route path="/candidature" element={<Admission />} />
        <Route path="/candidature/informations-personnelles" element={<Admission />} />
        <Route path="/candidature/scolarite" element={<Admission />} />
        <Route path="/candidature/documents" element={<Admission />} />
        <Route path="/candidature/cv-lettre-de-motivation" element={<Admission />} />
        <Route path="/candidature/paiement" element={<Admission />} />
        <Route path="/candidature/paiement/success" element={<PaymentSuccess />} />
        <Route path="/candidature/paiement/cancel" element={<PaymentCancel />} />
        <Route path="/home-two" element={<Hometwo />} />
        <Route path="/home-three" element={<Homethree />} />
        <Route path="/about" element={<About />} />
        <Route path="/courses" element={<Courses />} />
        <Route path="/courses/bachelor" element={<Bachelors />} />
        <Route path="/courses/master" element={<Masters />} />
        <Route path="/courses-2" element={<Coursestwo />} />
        <Route path="/single-courses" element={<Coursesdeatils />} />
        <Route path="/single-courses-2" element={<Coursesdeatilstwo />} />
        <Route path="/event" element={<Event />} />
        <Route path="/single-event" element={<Eventdeatils />} />
        <Route path="/projects" element={<Gallery />} />
        <Route path="/single-projects" element={<Projectdeatils />} />
        <Route path="/formations/tarifs" element={<Tarifs />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/team" element={<Teacher />} />
        <Route path="/team-single" element={<Teacherdeatils />} />
        <Route path="/404-error" element={<Error />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog-details" element={<Blogdeatils />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/password-reset" element={<Passwordreset />} />
        <Route path="/courses/master/rh-et-management-du-capital-humain" element={<MasterRH />} />
        <Route path="/courses/master/management-des-organisations-et-des-unites-operationnelles" element={<MasterMouo />} />
        <Route path="/courses/master/commerce-marketing-et-communication" element={<MasterComMarkCo />} />
        <Route path="/courses/master/management-de-la-qualite-qhse" element={<MasterQhse />} />
        <Route path="/courses/master/banque-assurance-et-fintech" element={<MasterBaf />} />
        <Route path="/courses/bachelor/charge-de-rh-et-de-gestion-du-capital-humain" element={<BachelorRH />} />
        <Route path="/courses/bachelor/charge-de-pilotage-des-unites-operationnelles" element={<BachelorCpuo />} />
        <Route path="/courses/bachelor/charge-de-commerce-marketing-et-communication" element={<BachelorComMarkCo />} />
        <Route path="/courses/bachelor/responsable-qualite-et-qhse" element={<BachelorQhse />} />
        <Route path="/courses/bachelor/charge-des-operations-banque-assurance-fintech" element={<BachelorBaf />} />
      </Routes>

      <Footer />
    </>
  );
}

export default Index;
