import React from 'react'
import Header from '../headerone/Main'
import Bredcom from '../Bredcom/Main'
import Form from '../menadmission/Form' 
import Image from "../Image/Main";
import { Link } from 'react-router-dom'

function Main() {
  return (
    <>
        <Header/>
        <Bredcom 
        subtitle="Bourse d'étude du PROSFIBE"/>
        <section className="about-area about-p pt-120 p-relative fix">
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="s-about-img p-relative  wow fadeInLeft animated" data-animation="fadeInLeft" data-delay=".4s">
                  <Image name="ImageAlternance" />
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s">
                  <div className="about-title second-title pb-25">
                    <h5><i className="fal fa-graduation-cap" /> PROSFIBE</h5>
                    <h3>Programme Social de Financement de Bourses d’Études,</h3>
                  </div>
                  <p className="txt-clr">
                    Le Programme Social de Financement de Bourses d’Etudes, souligne notre engagement Social et durable (RSE) en faveur des étudiants ayant obtenu des meilleurs résultats au baccalauréat, ayant été affectés dans une université, mais issus de familles modestes en incapacité partielle ou totale de prendre en charge  les études supérieures de leurs enfants. <br />
                    Il s’agit pour nous d’activer à travers des partenariats, l’engagement RSE des entreprises ou l’action des Fondations ou organisations assimilées, pour financer chaque année 5 à 10 Bourses d’études par parcours, soit 50 à 100 au total, destinés à ces enfants, à des tarifs préférentiels. <br />
                    Aussi, l'objectif ultime est d'améliorer les conditions d'apprentissage des étudiants et de fournir des environnements éducatifs adaptés.  Cette méthode contribuera à ajuster l’égalité des chances dans nos pays Africains.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <section
            className="steps-area2 p-relative fix"
            style={{ backgroundColor: "#032e3f" }}
          >
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6 col-md-12">
                  <div
                    className="step-img3 wow fadeInLeft animated"
                    data-animation="fadeInLeft"
                    data-delay=".4s"
                  >
                    <Image name="ImageConditionsProsfibe" />
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div
                    className="step-box step-box3 wow fadeInUp animated"
                    data-animation="fadeInUp"
                    data-delay=".4s"
                  >
                    <div className="text">
                      <h2>Conditions</h2>
                      <p>
                        Vous êtes bachelier.e.s ou  étudiant.e.s et rencontrez des difficultés matérielles pour poursuivre des études supérieures ? <br />
                        Vous pouvez souscrire à notre Programme Social de Financement de Bourses d’Etudes d'enseignement supérieur (PROSFIBE). Sachez que la bourse complète l'aide de la famille et ne remplace pas l'obligation alimentaire Aide qui consiste à fournir à un membre de sa famille tout ce qui lui est indispensable pour vivre (nourriture, vêtements, logement, soins médicaux, etc.) à la charge des parents.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
        <section id="prosfibe" className="cta-area cta-bg pt-50 pb-50" style={{ backgroundColor: "#3d77b7" }} >
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-8">
                    <div className="section-title cta-title wow fadeInLeft animated" data-animation="fadeInDown animated" data-delay=".2s" >
                    <h2>Critères</h2>
                    <p>
                      Il y a plusieurs conditions liées aux études, à l'âge, aux diplômes obtenus, à votre nationalité et à vos ressources.
                    </p>
                    </div>
                </div>
                <div className="col-lg-4 text-right">
                    <div className="cta-btn s-cta-btn wow fadeInRight animated mt-30" data-animation="fadeInDown animated" data-delay=".2s" >
                      <Link to="/prosfibe/demande" className="btn ss-btn smoth-scroll">
                          Faire une demande <i className="fal fa-long-arrow-right" />
                      </Link>
                    </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 col-md-12">
                  <div className="step-box2 wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s">
                    <div className="text text-white project-detail">
                      <h3 class="text-white">Études</h3>
                      <p>
                        Vous devez remplir toutes les conditions suivantes :
                      </p>
                      <ul className="pr-ul">
                        <li class="w100">
                            <div className="icon">
                                <i className="fal fa-check" />
                            </div>
                            <div className="text">
                              Vous venez d’avoir le BAC ou êtes inscrit en formation initiale en Côte d’Ivoire ou dans un autre pays africain
                            </div>
                        </li>
                        <li class="w100">
                            <div className="icon">
                                <i className="fal fa-check" />
                            </div>
                            <div className="text">
                              L'établissement est un établissement d'enseignement public ou privé habilité par l’état
                            </div>
                        </li>
                        <li class="w100">
                            <div className="icon">
                                <i className="fal fa-check" />
                            </div>
                            <div className="text">
                              Vous suivez des études supérieures à plein temps
                            </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12">
                    <div className="text text-white project-detail">
                      <h3 class="text-white">Âge</h3>
                      <ul className="pr-ul">
                        <li class="w100">
                            <div className="icon">
                                <i className="fal fa-check" />
                            </div>
                            <div className="text">
                              Vous devez avoir moins de 28 ans lors de votre 1ere demande de bourse (au 1er septembre de l'année des études)
                            </div>
                        </li>
                        <li class="w100">
                            <div className="icon">
                                <i className="fal fa-check" />
                            </div>
                            <div className="text">
                              À partir de 28 ans, vous devez poursuivre vos études pour continuer à percevoir la bourse
                            </div>
                        </li>
                        <li class="w100">
                            <div className="icon">
                                <i className="fal fa-check" />
                            </div>
                            <div className="text">
                              Cette limite d'âge peut être reportée si vous êtes dans l'une des conditions suivantes : <strong>Volontariat dans les armées</strong>, <strong>Service civique</strong>, <strong>Étudiant parent d'enfant</strong>, <strong>Étudiant handicapé</strong>.
                            </div>
                            
                        </li>
                      </ul>
                    </div>
                </div>
                <div className="col-lg-4 col-md-12">
                    <div className="text text-white project-detail">
                      <h3 class="text-white">Diplômes</h3>
                      <ul className="pr-ul">
                        <li class="w100">
                            <div className="text">
                              Vous devez avoir un bac de votre pays de nationalité (ou un titre/diplôme admis en équivalence ou en dispense pour l'inscription en 1ere année d'études supérieures)
                            </div>
                        </li>
                      </ul>
                    </div>
                </div>
              </div>
            </div>
        </section>

      <section className="steps-area2 p-relative fix" style={{ backgroundColor: "#032e3f" }}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div
                className="step-img3 wow fadeInLeft animated"
                data-animation="fadeInLeft"
                data-delay=".4s"
              >
                <Image name="ImageAlternanceDuree" />
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div
                className="step-box step-box3 wow fadeInUp animated"
                data-animation="fadeInUp"
                data-delay=".4s"
              >
                <div className="text">
                  <h2>Comment postuler ?</h2>
                  <p>
                    Vous devez constituer votre dossier social étudiant (DS-PROSFIBE) sur notre site pour faire votre demande de bourse. Téléchargez votre dossier ici (Créer lien)
                  </p>
                  <p>
                    Vous pouvez préparer et compléter les documents nécessaires à votre dossier social étudiant (DS- PROSFIBE) avant de le déposer. ICI (Créer un lien de souscription)
                  </p>
                </div>
                <div className="text text-white">
                  <strong>
                    À la fin de la saisie de votre DSE, vous recevrez un mail de confirmation de dépôt de votre demande.
                  </strong>
                  <br /><br />
                  <strong>
                    Si votre dossier est incomplet, un mail vous est également envoyé. Vous devrez alors déposer les documents manquants en vous connectant à votre compte.
                  </strong>
                </div>
                <div className="cta-btn s-cta-btn wow fadeInRight animated mt-30" data-animation="fadeInDown animated" data-delay=".2s" >
                  <Link to="/prosfibe/demande" className="btn ss-btn smoth-scroll">
                      Faire une demande <i className="fal fa-long-arrow-right" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Main