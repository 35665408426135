import React from "react";
import Image from "../Image/Main";

function University() {
  return (
    <>
      <section className="about-area about-p pt-120 pb-120 p-relative fix">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="s-about-img p-relative  wow fadeInLeft animated" data-animation="fadeInLeft" data-delay=".4s">
                <Image name="ImageEtudiantsDivers" />
                <div className="about-text second-about three-about">
                  <span><sub>+</sub>17</span>
                  <p>Années cumulées de pédagogie</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s">
                <div className="about-title second-title pb-25">
                  <h5><i className="fal fa-graduation-cap" /> Notre école</h5>
                  <h2>Quelques mots...</h2>
                </div>
                <p className="txt-clr">
                  <strong>Skills Bridge Academy</strong> est une école de commerce et de management spécialisée dans la formation des managers et
                  cadres de direction de demain. <br />
                  Conçus sur la base des référentiels et syllabus de certificateurs français des
                  centres de formation et d’apprentissage, nos cours confèrentles compétences requises par les meilleurs professionnels
                  européens et internationaux.
                </p>
                <p className="txt-clr">
                  Notre modèle hybride intègre un dispositif innovant qui prépare au Bachelor et master en alternance, proposant des
                  cours en distanciel, et des sessions en présentiel sous formede séminaires et workshop de consolidation des compétences.
                  Notre modèle d’acquisition de compétences intègre également des contrats d’apprentissage mis en œuvre sous la coupole d’un
                  tuteur. Nos étudiants consolident leur formation en environnement opérationnel dans une entreprise partenaire. Unprocess qualité porte cette démarche en accord avec les
                  livrets pédagogiques, à l’effet de concrétiser l’adéquation entre les objectifs pédagogiques et les besoins de compétences des entreprises.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default University;
