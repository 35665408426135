// CheckoutButton.js
import React from "react";
import { useStripe } from "@stripe/react-stripe-js";

function CheckoutButton() {
  const stripe = useStripe();

  const handleClick = async () => {
    const response = await fetch("https://api.skillsbridge-academy.com/create-checkout-session", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const { sessionId } = await response.json();

    const { error } = await stripe.redirectToCheckout({ sessionId });

    if (error) {
      console.error("Erreur de redirection vers Checkout:", error.message);
    }
  };

  return (
    <button className="btn btn-success" onClick={handleClick} disabled={!stripe}>
      Payer <i className="fal fa-credit-card" />
    </button>
  );
}

export default CheckoutButton;
