import React from 'react'
import Bredcom from '../Bredcom/Main'
import Header from '../headerone/Main'
import BachelorBaf from '../mencoursedetails/BachelorBaf'
import Courses from '../menhome/Courses'
import Brand from '../menhomethree/Brand'

function Main() {
  return (
    <>
        <Header/>
        <Bredcom 
          title="Bachelors" 
          titleLink="/courses/bachelors" 
          subtitle="Bachelor - Chargé des opérations Banque, Assurance et Fintech" />
        <BachelorBaf/>
        <Courses/>
    </>
  )
}

export default Main