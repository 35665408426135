import React from 'react'
import { Link } from 'react-router-dom'
import Image from '../Image/Main'
import { useState } from 'react'

function MasterMouo() {
    const [country, setCountry] = useState("ci")
    const [course, setCourse] = useState("m1")
  return (
    <>
        <section className="project-detail">
            <div className="container">
                <div className="lower-content">
                <div className="row">
                    <div className="text-column col-lg-9 col-md-9 col-sm-12">
                    {/*<h2>
                        <span className="text-primary">Master</span> <br />
                        Management des organisations et des unités opérationnelles
                    </h2>*/}
                    <div className="upper-box">
                        <div className="single-item-carousel owl-carousel owl-theme">
                        <figure className="image">
                            <Image name="ImageCourseManagement" />
                        </figure>
                        </div>
                    </div>
                    <div className="inner-column">
                        {/*<div className="course-meta2 review style2 clearfix mb-30">
                            <ul className="left">
                                <li>
                                    <div className="author">
                                        <div className="thumb">
                                            <Image name="ImageAvatar" />
                                        </div>
                                        <div className="text">
                                        <Link to="/single-courses-2">Prénom NON</Link>
                                        <p>Enseignant principal</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <ul className="right">
                                <li className="price">6800€</li>
                            </ul>
                        </div>*/}
                        <h3>Présentation</h3>
                        <p>
                        Notre parcours en Management des Organisations et des Unités Opérationnelles est conçu pour les futurs professionnels souhaitant maîtriser les rouages de la gestion d’équipe et des opérations. Ce programme vous dote des compétences nécessaires pour piloter les performances organisationnelles et garantir l’efficacité opérationnelle.
                        </p>
                        <h4>Compétences visées</h4>
                        <ul className="pr-ul">
                            <li>
                                <div className="icon">
                                    <i className="fal fa-check" />
                                </div>
                                <div className="text">
                                    Piloter le management des équipes et des processus.
                                </div>
                            </li>
                            <li>
                                <div className="icon">
                                    <i className="fal fa-check" />
                                </div>
                                <div className="text">
                                    Acquérir des outils de gestion moderne pour optimiser les performances.
                                </div>
                            </li>
                            <li>
                                <div className="icon">
                                    <i className="fal fa-check" />
                                </div>
                                <div className="text">
                                    Gérer le changement et l’innovation au sein des organisations.
                                </div>
                            </li>
                            <li>
                                <div className="icon">
                                    <i className="fal fa-check" />
                                </div>
                                <div className="text">
                                    Élaborer et déployer des stratégies opérationnelles en réponse aux défis actuels.
                                </div>
                            </li>
                            <li>
                                <div className="icon">
                                    <i className="fal fa-check" />
                                </div>
                                <div className="text">
                                    Piloter la transformation des organisations en intégrant les défis technologiques et sociétaux ainsi que les enjeux de diversité et d'inclusion.
                                </div>
                            </li>
                        </ul>
                        <h4>Spécificités de la formation</h4>
                        <ul className="pr-ul">
                            <li>
                                <div className="icon">
                                    <i className="fal fa-check" />
                                </div>
                                <div className="text">
                                    En alternance, en formation continue ou initiale.
                                </div>
                            </li>
                            <li>
                                <div className="icon">
                                    <i className="fal fa-check" />
                                </div>
                                <div className="text">
                                    Formation à distance et en présentiel pour une flexibilité maximale.
                                </div>
                            </li>
                            <li>
                                <div className="icon">
                                    <i className="fal fa-check" />
                                </div>
                                <div className="text">
                                    Modules interactifs animés par des professionnels expérimentés.
                                </div>
                            </li>
                            <li>
                                <div className="icon">
                                    <i className="fal fa-check" />
                                </div>
                                <div className="text">
                                    Séminaires de formation en présentiel avec des professionnels du secteur.
                                </div>
                            </li>
                            <li>
                                <div className="icon">
                                    <i className="fal fa-check" />
                                </div>
                                <div className="text">
                                    Formation axée sur les pratiques digitales innovantes.
                                </div>
                            </li>
                            <li>
                                <div className="icon">
                                    <i className="fal fa-check" />
                                </div>
                                <div className="text">
                                    Études de cas réels et projets en entreprise pour une mise en pratique immédiate.
                                </div>
                            </li>
                        </ul>
                        <h4>Conditions d'Accès</h4>
                        <ul className="pr-ul">
                            <li>
                                <div className="icon">
                                    <i className="fal fa-check" />
                                </div>
                                <div className="text">
                                    Selon le niveau Bac, Bac+2, Licence et Master ou équivalent.
                                </div>
                            </li>
                            <li>
                                <div className="icon">
                                    <i className="fal fa-check" />
                                </div>
                                <div className="text">
                                    La sélection des candidatures s’effectue sur dossier et, le cas échéant, sur entretien de motivation, validation du projet professionnel et tests.
                                </div>
                            </li>
                        </ul>
                        <h3>Débouchés professionnels</h3>
                        <p>
                        Chef d’équipe, Chef de service ou de département, Directeur d'unité opérationnelle, Responsable de projet, Consultant en management, etc.
                        </p>
                    </div>
                    </div>
                    <div className="col-lg-3">
                    <aside className="sidebar-widget info-column">
                        <div className="inner-column3">
                        <h3>Informations</h3>
                        <div className="pd-1">
                            <div className="d-flex justify-content-between">
                                <select value={country} onChange={(e) => setCountry(e.target.value)}>
                                    <option value="ci">Côte d'Ivoire</option>
                                    <option value="fr">France</option>
                                    <option value="sn">Sénégal</option>
                                </select>
                                &nbsp;
                                <select value={course} onChange={(e) => setCourse(e.target.value)}>
                                    <option value="m1">Master 1</option>
                                    <option value="m2">Master 2</option>
                                </select>
                            </div>
                            {(country != "fr") ? 
                                <div className="priceing prix-afrique mt-3">
                                    {(course == "m1") ? <h4 className="text-primary text-center">4.500.000F CFA</h4> : <h4 className="text-primary text-center">5.200.000F CFA</h4>}
                                </div> 
                                :
                                <div className="priceing prix-france mt-3">
                                    {(course == "m1") ? <h4 className="text-primary text-center">8.500€</h4> : <h4 className="text-primary text-center">8.500€</h4>}
                                </div>
                            }
                            <div className="text-center">Pré-inscription : {(country != "fr") ? "100.000F CFA" : "150€"}*</div>
                        </div>
                        <ul className="project-info clearfix">
                            <li>
                                <span className="icon fal fa-user-graduate" />{" "}
                                <strong>Diplôme: </strong> <span>Titre de niveau 7 (BAC +5)</span>
                            </li>
                            <li>
                                <span className="icon fal fa-home-lg-alt" />{" "}
                                <strong>Formation</strong> <span>En alternance</span>
                            </li>
                            <li>
                                <span className="icon fal fa-book" />{" "}
                                <strong>Pré-requis:</strong> 
                                <span>{(course == "m1") ? "Bachelor 3 ou Licence 3": "Master 1"}</span>
                            </li>
                            <li>
                                <span className="icon fal fa-clock" />{" "}
                                <strong>Durée: </strong> 
                                    <span>{(course == "m1") ? "2 ans" : "1 an"}</span>
                            </li>
                            <li className="hide">
                                <span className="icon fal fa-user" />{" "}
                                <strong>Classe: </strong> <span>20 étudiants</span>
                            </li>
                            <li>
                                <span className="icon fal fa-edit" />{" "}
                                <strong>Contrat: </strong> <span>Apprentissage et professionnalisation</span>
                            </li>
                            <li>
                                <div className="slider-btn">
                                    <Link to={`/candidature?country=${country}&level=${course}&course=mouo`} className="btn ss-btn smoth-scroll">
                                        S'inscrire <i className="fal fa-long-arrow-right" />
                                    </Link>
                                </div>
                            </li>
                        </ul>
                        </div>
                    </aside>
                    </div>
                </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default MasterMouo