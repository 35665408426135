import React from 'react'
import Bredcom from '../Bredcom/Main'
import Header from '../headerone/Main'
import First from '../mencourses/First'
import Brand from '../menhomethree/Brand'


function Main() {
  return (
    <>
        <Header/>
        <Bredcom 
        subtitle="Tarifs des formations"/>
        <section className="shop-area pt-120 pb-120 p-relative">
            <div className="container">
                <table className='table table-bordered'>
                    <thead className='thead-dark'>
                        <tr>
                            <th></th>
                            <th>Côte d'Ivoire</th>
                            <th>France</th>
                            <th>Sénégal</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope='row'>Bachelor 1</th>
                            <td>2.300.000F CFA</td>
                            <td>6.500€</td>
                            <td>2.300.000F CFA</td>
                        </tr>
                        <tr>
                            <th scope='row'>Bachelor 2</th>
                            <td>3.000.000F CFA</td>
                            <td>6.500€</td>
                            <td>3.000.000F CFA</td>
                        </tr>
                        <tr>
                            <th scope='row'>Bachelor 3</th>
                            <td>3.800.000F CFA</td>
                            <td>7.500€</td>
                            <td>3.800.000F CFA</td>
                        </tr>
                        <tr>
                            <th scope='row'>Master 1</th>
                            <td>4.500.000F CFA</td>
                            <td>8.500€</td>
                            <td>4.500.000F CFA</td>
                        </tr>
                        <tr>
                            <th scope='row'>Master 2</th>
                            <td>5.200.000F CFA</td>
                            <td>8.500€</td>
                            <td>5.200.000F CFA</td>
                        </tr>
                    </tbody>
                </table>
                <i>NB: Ces tarifs sont les mêmes pour tous nos parcours de formations.</i>
            </div>
        </section>
        <Brand/>
    </>
  )
}

export default Main