import React from "react";
import Image from "../Image/Main";
import { Link } from 'react-router-dom'

function CreativeLab() {
  return (
    <>
      <section id="creativeLab" className="steps-area2 p-relative fix" style={{ backgroundColor: "#3d77b7" }}>
        <div className="animations-02">
          <Image name="ImageBg10" />
        </div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div
                className="step-box step-box2 wow fadeInUp animated"
                data-animation="fadeInUp"
                data-delay=".4s"
              >
                <div className="text">
                  <h2>Creative Lab</h2>
                  <p className="text-white">
                    L’entreprenariat est aujourd’hui la clé de l’innovation des modèles économiques qui animent nos marchés. <br /> 
                    Conscients des défis de notre époque, notre laboratoire d’idéation et d’innovation est un pan de notre dispositif d’opérationnalisation de nos étudiants.  <br /> Stimuler et faire s’exprimer le génie de chacun, sur la résolution de besoins précis des consommateurs, est notre conviction pour transformer le tissu industriel de nos pays.  <br />  <br /> Creative Lab est ouvert à tous les étudiants désireux d’imposer leur leadership dans nos économies par des idées nouvelles. Nous les y accompagnons pour que nos étudiants soient une varie valeur ajoutée à nos sociétés en mutation rapide et dynamique.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div
                className="step-img2 wow fadeInLeft animated"
                data-animation="fadeInLeft"
                data-delay=".4s"
              >
                <Image name="ImageCreativeLab" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="steps-area2 p-relative fix"
        style={{ backgroundColor: "#032e3f" }}
      >
        <div className="container">
          <div className="animations-08">
            <Image name="ImageBg20" />
          </div>
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div
                className="step-img3 wow fadeInLeft animated"
                data-animation="fadeInLeft"
                data-delay=".4s"
              >
                <Image name="ImageDiagnostic" />
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div
                className="step-box step-box3 wow fadeInUp animated"
                data-animation="fadeInUp"
                data-delay=".4s"
              >
                <div className="text">
                  <h2>Diagnostic</h2>
                  <p className="text-white">
                    Concours de Pitching de solution innovante sur des problématiques concrètes des entreprises locales, DIAGNOSTIC se veut être le trait d’union entre la pratique des métiers issues des différents parcours que nous proposons, et les recherches liées à la consolidation des compétences métiers des étudiants. <br /><br />
                    Rechercher des solutions innovantes sur la base de problématiques réelles des entreprises, transposées dans des cas pratiques. Cela représente pour chaque étudiant l’opportunité d’une immersion en environnement professionnel tant pour la compréhension des enjeux, que pour l’efficacité des solutions à proposer.
                  </p>
                  <Link to="/about/diagnostic" className="btn ss-btn smoth-scroll mt-20">
                    En savoir plus <i className="fal fa-long-arrow-right" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default CreativeLab;
