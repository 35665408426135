import React from "react";

function PasswordResetForm() {
  return (
    <>
      <section
        id="contact"
        className="contact-area after-none contact-bg pt-120 pb-120 p-relative fix"
        style={{ background: "#e7f0f8" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 order-2">
              <div className="contact-bg">
                <div className="section-title center-align text-center mb-50">
                  <h4>Vous souhaitez réinitialiser votre mot de passe ?</h4>
                </div>
                <form
                  action=""
                  method="post"
                  className="contact-form mt-30 text-center"
                >
                  <div className="row">
                    <div className="col-lg-4 offset-lg-4">
                      <div className="col-lg-12">
                        <div className="contact-field p-relative c-subject mb-30">
                          <input
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Entrez votre adresse email"
                            required
                          />
                          <i className="icon fal fa-envelope" />
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="slider-btn  text-center">
                        <button
                          className="btn ss-btn"
                          data-animation="fadeInRight"
                          data-delay=".8s"
                        >
                          Réinitialiser
                          <i class="fal fa-link"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PasswordResetForm;
