import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";

function SocialLogin({ separator }) {
  const [path, setPath] = useState("");
  const showBtnText = !isMobile;
  useEffect(() => {
    //console.log("path -->", path);
  }, [path]);

  return (
    <div className="row text-center contact-form" id="social-login">
      <div className="col-lg-4 offset-lg-4">
        <button
          className="btn ss-btn btn-social google"
          data-animation="fadeInRight"
          data-delay=".8s"
          onClick={() => setPath("google")}
        >
          <i className="fab fa-google" /> {showBtnText && "Google"}
        </button>
        <button
          className="btn ss-btn btn-social linkedin"
          data-animation="fadeInRight"
          data-delay=".8s"
          onClick={() => setPath("linkedin")}
        >
          <i className="fab fa-linkedin" /> {showBtnText && "LinkedIn"}
        </button>
        <button
          className="btn ss-btn btn-social apple"
          data-animation="fadeInRight"
          data-delay=".8s"
          onClick={() => setPath("apple")}
        >
          <i className="fab fa-apple" /> {showBtnText && "Apple"}
        </button>
      </div>

      {separator ? (
        <div className="col-lg-4 offset-lg-4 pt-50">
          <div className="hl">
            <span className="inner">ou</span>
          </div>
        </div>
      ) : undefined}
    </div>
  );
}

export default SocialLogin;
