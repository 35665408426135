import React from 'react'
import Header from '../headerone/Main'
import Bredcom from '../Bredcom/Main'
import Form from '../menadmission/Form' 
import Image from "../Image/Main";

function Main() {
  return (
    <>
        <Header/>
        <Bredcom 
        title="Accueil" 
        subtitle="Ingénierie pédagogique"/>
        <section className="about-area about-p pt-120 pb-120 p-relative fix">
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="s-about-img p-relative  wow fadeInLeft animated" data-animation="fadeInLeft" data-delay=".4s">
                  <Image name="ImageAlternance" />
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s">
                  <div className="about-title second-title pb-25">
                    <h5><i className="fal fa-graduation-cap" /> Alternance</h5>
                    <h3>LA FORMATION EN ALTERNANCE</h3>
                  </div>
                  <p className="txt-clr">
                    <strong>L’alternance</strong> est un système de formation fondé sur une phase pratique et une phase théorique qui alternent. Comprenant deux types de contrats, <strong>le contrat d’apprentissage</strong> et <strong>le contrat de professionnalisation</strong>, l’alternance permet de concilier travail en entreprise et formation théorique.
                  </p>
                  <ul>
                    <li>
                      <div className="icon">
                          <i className="fal fa-check" />
                      </div>
                      <div className="text">
                          <p>
                            Le contrat d’apprentissage est une voie de formation initiale qui a pour but d’obtenir un diplôme d’État (CAP, Baccalauréat, BTS, Licence, Master,…) ou un titre à finalité professionnelle inscrit au répertoire national des certifications professionnelles (RNCP), dont l’ensemble des titres professionnels relevant du ministère du Travail.
                          </p>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                          <i className="fal fa-check" />
                      </div>
                      <div className="text">
                          <p>
                            Le contrat de professionnalisation est une voie de formation continue qui a pour but d’acquérir une qualification professionnelle reconnue (un diplôme ou un titre professionnel enregistré dans le Répertoire national des certifications professionnelles (RNCP) ; un certificat de qualification professionnelle (CQP) ; une qualification reconnue dans les classifications d’une convention collective nationale).
                          </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <section
            className="steps-area2 p-relative fix"
            style={{ backgroundColor: "#032e3f" }}
          >
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6 col-md-12">
                  <div
                    className="step-img3 wow fadeInLeft animated"
                    data-animation="fadeInLeft"
                    data-delay=".4s"
                  >
                    <Image name="ImageStepBg01" />
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div
                    className="step-box step-box3 wow fadeInUp animated"
                    data-animation="fadeInUp"
                    data-delay=".4s"
                  >
                    <div className="text">
                      <h2>LES FONDEMENTS</h2>
                      <p>
                        La loi du 5 septembre du 2018 a également crée un nouveau dispositif afin de redynamiser les modalités de formations ouvertes aux salariés : la reconversion ou la promotion par alternance (Pro-A). Ce dispositif s’inscrit en complément du plan de développement des compétences de l’entreprise et du compte personnel de formation (CPF) du salarié. Mis en œuvre à l’initiative du salarié ou de l’entreprise, il peut être mobilisé dans une optique d’évolution, de réorientation professionnelle ou de co-construction de projets qualifiants entre salariés et employeurs.
                      </p>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
        <section id="creativeLab" className="steps-area2 p-relative fix" style={{ backgroundColor: "#fff" }}>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12">
                <div className="step-box2 wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s">
                  <div className="text project-detail">
                    <h3 class="text-primary">Pourquoi choisir l’alternance ?</h3>
                    <ul className="pr-ul">
                      <li>
                          <div className="icon">
                              <i className="fal fa-check" />
                          </div>
                          <div className="text">
                            Pour obtenir un diplôme ou une qualification professionnelle reconnue par l’État
                          </div>
                      </li>
                      <li>
                          <div className="icon">
                              <i className="fal fa-check" />
                          </div>
                          <div className="text">
                            Pour avoir une expérience professionnelle dans le métier choisi et être rapidement opérationnel
                          </div>
                      </li>
                      <li>
                          <div className="icon">
                              <i className="fal fa-check" />
                          </div>
                          <div className="text">
                            Pour percevoir un salaire tout en poursuivant ses études
                          </div>
                      </li>
                      <li>
                          <div className="icon">
                              <i className="fal fa-check" />
                          </div>
                          <div className="text">
                            Parce que c’est un excellent tremplin pour l’emploi
                          </div>
                      </li>
                  </ul>
                </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div
                  className="wow fadeInLeft animated radius2em"
                  data-animation="fadeInLeft"
                  data-delay=".4s"
                >
                  <Image name="ImageAlternanceProA" className="radius2em" />
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12">
                <div className="step-box2 wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s">
                  <div className="text project-detail">
                    <h3 class="text-primary">L’alternance, pour qui ?</h3>
                    <ul className="pr-ul">
                      <li class="w100">
                          <div className="icon">
                              <i className="fal fa-check" />
                          </div>
                          <div className="text">
                            Le contrat d’apprentissage s’adresse aux jeunes : entre 16 et 29 ans révolus.
                          </div>
                      </li>
                      <li class="w100">
                        <p>
                          Il est toutefois possible de conclure un contrat d’apprentissage, au-delà de 30 ans, si vous êtes reconnu travailleur handicapé, sportif de haut niveau ou si vous avez un projet de création ou de reprise d’entreprise nécessitant le diplôme préparé.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                  <div className="text project-detail">
                    <h3 class="text-primary">Le contrat de professionnalisation s’adresse aux</h3>
                    <ul className="pr-ul">
                      <li class="w100">
                          <div className="icon">
                              <i className="fal fa-check" />
                          </div>
                          <div className="text">
                            jeunes entre 16 et 25 ans
                          </div>
                      </li>
                      <li class="w100">
                          <div className="icon">
                              <i className="fal fa-check" />
                          </div>
                          <div className="text">
                            demandeurs d’emploi âgés de 26 ans et plus
                          </div>
                      </li>
                      <li class="w100">
                          <div className="icon">
                              <i className="fal fa-check" />
                          </div>
                          <div className="text">
                            aux bénéficiaires du revenu de solidarité active (RSA), de l’allocation spécifique de solidarité (ASS), de l’allocation adulte handicapé (AAH) ou d’un contrat unique d’insertion (CUI).
                          </div>
                      </li>
                    </ul>
                  </div>
              </div>
            </div>
          </div>
      </section>

      <section className="steps-area2 p-relative fix" style={{ backgroundColor: "#032e3f" }}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div
                className="step-img3 wow fadeInLeft animated"
                data-animation="fadeInLeft"
                data-delay=".4s"
              >
                <Image name="ImageAlternanceDuree" />
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div
                className="step-box step-box3 wow fadeInUp animated"
                data-animation="fadeInUp"
                data-delay=".4s"
              >
                <div className="text">
                  <h2>Combien de temps ?</h2>
                  <p>
                    Le contrat d’apprentissage et le contrat de professionnalisation sont conclus en contrat à durée limitée ou dans le cadre d’un CDI.
                  </p>
                </div>
                <div className="text">
                  <h3 class="text-white">La durée varie en fonction de la formation choisie</h3>
                  <p>
                    La période d’apprentissage varie de 6 mois à 3 ans, voire 4 ans pour les travailleurs handicapés.
                  </p>
                  <br />
                  <p>
                    La période de professionnalisation de 6 mois à 1 an, voire 3 ans dans certains cas.
                  </p>
                </div>
                <div className="text">
                  <p>
                    La durée de la formation théorique ne peut pas être inférieure à 25 % de la durée globale du contrat dans le cadre du contrat d’apprentissage.
                  </p>
                  <br />
                  <p>
                    La durée de la formation théorique représente entre 15 à 25 % de la durée du contrat sans pouvoir être inférieure à 150 heures dans le cadre du contrat de professionnalisation, sauf dérogation de branche.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="row mt-50 center">
        <div className="col-lg-12 p-relative">
            <div className="section-title center-align mb-50 wow fadeInDown animated" data-animation="fadeInDown" data-delay=".4s" >
                <h2>Quelle rémunération ?</h2>
            </div>
        </div>
      </div>
      <section className="steps-area2 p-relative fix" style={{ backgroundColor: "#fff" }}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12">
              <h3>Contrat d’apprentissage</h3>
              <div className="text">
                <p>
                  L’apprenti bénéficie d’une rémunération variant en fonction de son âge ; en outre, sa rémunération progresse chaque nouvelle année d’exécution de son contrat. Le salaire minimum perçu par l’apprenti correspond à un pourcentage du Smic ou du SMC (salaire minimum conventionnel de l’emploi occupé) pour les 21 ans et plus.
                </p>
                <p>
                  Pour les contrats conclus à partir du 1er janvier 2019
                </p>
                <table>
                  <thead>
                    <th>Année d'exécution du contrat</th>
                    <th>Apprenti de moins de 18 ans</th>
                    <th>Apprenti de 18 ans à 20 ans</th>
                    <th>Apprenti de 21 ans à 25 ans</th>
                    <th>Apprenti de 26 ans et plus</th>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1ere année</td>
                      <td>27%</td>
                      <td>43%</td>
                      <td>53%</td>
                      <td>100%*</td>
                    </tr>
                    <tr>
                      <td>2e année</td>
                      <td>39%</td>
                      <td>51%</td>
                      <td>61%</td>
                      <td>100%*</td>
                    </tr>
                    <tr>
                      <td>3e année</td>
                      <td>55%</td>
                      <td>67%</td>
                      <td>78%</td>
                      <td>100%*</td>
                    </tr>
                  </tbody>
                </table>
                <i>* ou du salaire minimum conventionnel de l’emploi occupé</i>
                <div className="text">
                  <p>
                    Des dispositions conventionnelles ou contractuelles peuvent prévoir une rémunération plus favorable pour le salarié. En cas de succession de contrats, la rémunération est au moins égale au minimum réglementaire de la dernière année du précédent contrat, sauf changement de tranche d’âge plus favorable à l’apprenti.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12">
              <h3>Contrat de professionnalisation</h3>
              <div className="text">
                <p>
                  La rémunération varie en fonction du niveau de sa formation initiale et de son âge.
                </p>
                <p>
                  Niveau de formation ou de qualification avant le contrat de professionnalisation
                </p>
                <table>
                  <thead>
                    <th>Âge</th>
                    <th>Inférieur au baccalauréat</th>
                    <th>Égal ou supérieur au baccalauréat</th>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Moins de 21 ans</td>
                      <td>Au moins 55% du smic</td>
                      <td>Au moins 65% du smic</td>
                    </tr>
                    <tr>
                      <td>21 ans à 25 ans révolus</td>
                      <td>Au moins 70% du smic</td>
                      <td>Au moins 80% du smic</td>
                    </tr>
                    <tr>
                      <td>26 ans et plus</td>
                      <td>Au moins le smic ou 85% de la rémunération minimale conventionnelle ordinaire</td>
                      <td>Au moins le smic ou 85% de la rémunération minimale conventionnelle ordinaire</td>
                    </tr>
                  </tbody>
                </table>
                <div className="text">
                  <p>
                    Des dispositions conventionnelles ou contractuelles peuvent prévoir une rémunération plus favorable pour le salarié.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Main