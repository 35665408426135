import React from "react";
import PasswordResetForm from "../authentication/PasswordResetForm";
import Header from "../headerone/Main";

function Main() {
  return (
    <>
      <Header />
      <PasswordResetForm />
    </>
  );
}

export default Main;
