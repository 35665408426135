import React from 'react'
import Bredcom from '../Bredcom/Main'
import Header from '../headerone/Main'
import BachelorCpuo from '../mencoursedetails/BachelorCpuo'
import Courses from '../menhome/Courses'
import Brand from '../menhomethree/Brand'

function Main() {
  return (
    <>
        <Header/>
        <Bredcom 
          title="Bachelors" 
          titleLink="/courses/bachelors" 
          subtitle="Bachelor - Chargé de pilotage des unités opérationnelles" />
        <BachelorCpuo/>
        <Courses/>
    </>
  )
}

export default Main