import React from 'react'
import Logo from '../../assets/img/logo/logo.png'
import LogoBlanc from '../../assets/img/logo/logo-blanc-sba.png'
import ImgaeStar from '../../assets/img/bg/star.png'
import Image404 from '../../assets/img/bg/404-img.png'
import ImageCtaBg from '../../assets/img/bg/cta_bg.png'
import LogoFooter from '../../assets/img/logo/f_logo.png'
import ImageBg01 from '../../assets/img/bg/an-img-01.png'
import ImageBg02 from '../../assets/img/bg/an-img-02.png'
import ImageBg03 from '../../assets/img/bg/an-img-03.png'
import ImageBg04 from '../../assets/img/bg/an-img-04.png'
import ImageBg05 from '../../assets/img/bg/an-img-05.png'
import ImageBg06 from '../../assets/img/bg/an-img-06.png'
import ImageBg10 from '../../assets/img/bg/an-img-10.png'
import ImageBg11 from '../../assets/img/bg/an-img-11.png'
import ImageBg12 from '../../assets/img/bg/an-img-12.png'
import ImageBg13 from '../../assets/img/bg/an-img-13.png'
import ImageBg14 from '../../assets/img/bg/an-img-14.png'
import ImageBg15 from '../../assets/img/bg/an-img-15.png'
import ImageBg16 from '../../assets/img/bg/an-img-16.png'
import ImageBg17 from '../../assets/img/bg/an-img-17.png'
import ImageBg18 from '../../assets/img/bg/an-img-18.png'
import ImageBg19 from '../../assets/img/bg/an-img-19.png'
import ImageBg20 from '../../assets/img/bg/an-img-20.png'
import ImageTeam1 from '../../assets/img/team/team01.jpg'
import ImageTeam2 from '../../assets/img/team/team02.jpg'
import ImageTeam3 from '../../assets/img/team/team03.jpg'
import ImageTeam4 from '../../assets/img/team/team04.jpg'
import ImageTeam5 from '../../assets/img/team/team05.jpg'
import ImageTeam9 from '../../assets/img/team/team09.png'
import ImageTeam10 from '../../assets/img/team/team10.png'
import ImageTeam11 from '../../assets/img/team/team11.png'
import ImageTeam12 from '../../assets/img/team/team12.png'
import ImageBgEnv01 from '../../assets/img/bg/evn-img-1.jpg'
import ImageBgEnv02 from '../../assets/img/bg/evn-img-2.jpg'
import ImageBgEnv03 from '../../assets/img/bg/evn-img-3.jpg'
import ImageBgEnv04 from '../../assets/img/bg/evn-img-4.jpg'
import ImageBgEnv05 from '../../assets/img/bg/evn-img-5.jpg'
import ImageBgEnv06 from '../../assets/img/bg/evn-img-6.jpg'
import ImageBgSteps from '../../assets/img/bg/steps-img.png'
import ImageMailing from '../../assets/img/icon/mailing.png'
import ImageCouIcon from '../../assets/img/icon/cou-icon.png'
import ImageInnerb1 from '../../assets/img/blog/inner_b1.jpg'
import ImageInnerb2 from '../../assets/img/blog/inner_b2.jpg'
import ImageInnerb3 from '../../assets/img/blog/inner_b3.jpg'
import ImageInnerb4 from '../../assets/img/blog/inner_b4.jpg'
import ImageInnerb5 from '../../assets/img/blog/inner_b5.jpg'
import ImageInnerb6 from '../../assets/img/blog/inner_b6.jpg'
import ImageInnerb7 from '../../assets/img/blog/inner_b7.jpg'
import ImageClass1  from '../../assets/img/class/class-1.jpg'
import ImageClass2 from '../../assets/img/class/class-2.jpg'
import ImageClass3  from '../../assets/img/class/class-3.jpg'
import ImageClass4  from '../../assets/img/class/class-4.jpg'
import ImageClass5  from '../../assets/img/class/class-5.jpg'
import ImageClass6  from '../../assets/img/class/class-6.jpg'
import ImageClass7  from '../../assets/img/class/class-7.jpg'
import ImageClass8  from '../../assets/img/class/class-8.jpg'
import ImageClass9  from '../../assets/img/class/class-9.jpg'
import ImageClass10  from '../../assets/img/class/class-10.jpg'
import ImageStepBg01 from '../../assets/img/bg/steps-img-2.png'
import ImageSceIcon1 from '../../assets/img/icon/sve-icon1.png'
import ImageSceIcon2 from '../../assets/img/icon/sve-icon2.png'
import ImageSceIcon3 from '../../assets/img/icon/sve-icon3.png'
import ImageSceIcon7 from '../../assets/img/icon/sve-icon7.png'
import ImageSceIcon8 from '../../assets/img/icon/sve-icon8.png'
import ImageSceIcon9 from '../../assets/img/icon/sve-icon9.png'
import ImageSceIcon10 from '../../assets/img/icon/sve-icon10.png'
import ImageBlog1 from '../../assets/img/blog/s-blogimg-01.png'
import ImageBlog2 from '../../assets/img/blog/s-blogimg-02.png'
import ImageBrandLogo1 from '../../assets/img/brand/b-logo1.png'
import ImageBrandLogoBeniking from '../../assets/img/brand/logo-beniking-formations.jpg'
import ImageBrandLogoQualiopi from '../../assets/img/brand/logo-qualiopi.jpg'
import ImageBrandLogoFdfp from '../../assets/img/brand/logo-fdfp.jpg'
import ImageBrandLogoCames from '../../assets/img/brand/logo-cames.jpg'
import ImageBrandLogoPolygloth from '../../assets/img/brand/logo-polygloth.jpg'
import ImageCourse1 from '../../assets/img/bg/couress-img-1.jpg'
import ImageCourseRH from '../../assets/img/formations/rh-management.jpg'
import ImageCourseBanque from '../../assets/img/formations/banque-assurance.jpg'
import ImageCourseCommerce from '../../assets/img/formations/commerce-communication.jpg'
import ImageCourseQhse from '../../assets/img/formations/qhse.jpg'
import ImageCourseManagement from '../../assets/img/formations/management.jpg'
import ImageCourseManagementBachelor from '../../assets/img/formations/bachelor-management.jpg'
import ImageAlternance from '../../assets/img/formations/alternance.jpg'
import ImageAlternanceProA from '../../assets/img/formations/alternance-pro-a.jpg'
import ImageAlternanceDuree from '../../assets/img/formations/alternance-duree.jpg'
import ImageValeurs from '../../assets/img/sba/valeurs.jpg'
import ImageEtudianteAdmission from '../../assets/img/sba/etudiante.jpg'
import ImageEtudiantsDivers from '../../assets/img/sba/etudiants-divers.jpg'
import ImageConditionsProsfibe from '../../assets/img/sba/conditions-prosfibe.jpg'
import ImageMobiliteInternationale from '../../assets/img/sba/mobilite-internationale.jpg'
import ImageEtudianteBrillante from '../../assets/img/sba/etudiante-brillante.jpg'
import ImageProA from '../../assets/img/sba/pro-a.jpg'
import ImageCreativeLab from '../../assets/img/sba/creative-lab.jpg'
import ImageDiagnostic from '../../assets/img/sba/diagnostic.jpg'
import ImageCVDossier from '../../assets/img/sba/cv-dossier.jpg'
import ImagePlayButton from '../../assets/img/bg/play-button.png'
import ImageFeaIcon01 from '../../assets/img/icon/fea-icon01.png'
import ImageFeaIcon02 from '../../assets/img/icon/fea-icon02.png'
import ImageFeaIcon03 from '../../assets/img/icon/fea-icon03.png'
import ImagePhoneCall from '../../assets/img/icon/phone-call.png'
import ImageQtIcon from '../../assets/img/testimonial/qt-icon.png'
import ImageCouress02 from '../../assets/img/bg/couress-img-2.jpg'
import ImageCouress03 from '../../assets/img/bg/couress-img-3.jpg'
import ImageCouress04 from '../../assets/img/bg/couress-img-4.jpg'
import ImageCouress05 from '../../assets/img/bg/couress-img-5.jpg'
import ImageCouress06 from '../../assets/img/bg/couress-img-6.jpg'
import ImagePlayButton2 from '../../assets/img/bg/play-button2.png'
import ImageAbout2 from '../../assets/img/features/about_img_02.png'
import ImageAbout4 from '../../assets/img/features/about_img_04.png'
import ImageAbout3 from '../../assets/img/features/about_img_03.png'
import ImageAbout5 from '../../assets/img/features/about_img_05.png'
import ImageSliderShape1 from '../../assets/img/bg/slider_shape01.png'
import ImageSliderShape2 from '../../assets/img/bg/slider_shape02.png'
import ImageSliderShape3 from '../../assets/img/bg/slider_shape03.png'
import ImageSliderShape4 from '../../assets/img/bg/slider_shape04.png'
import ImageContactIcon1 from '../../assets/img/bg/contact-icon01.png'
import ImageContactIcon2 from '../../assets/img/bg/contact-icon02.png'
import ImageContactIcon3 from '../../assets/img/bg/contact-icon03.png'
import ImageAvatar from '../../assets/img/testimonial/testi_avatar.png'
import ImpagePorFolio1 from '../../assets/img/gallery/protfolio-img01.jpg'
import ImpagePorFolio2 from '../../assets/img/gallery/protfolio-img02.jpg'
import ImpagePorFolio3 from '../../assets/img/gallery/protfolio-img03.jpg'
import ImpagePorFolio4 from '../../assets/img/gallery/protfolio-img04.jpg'
import ImpagePorFolio5 from '../../assets/img/gallery/protfolio-img05.jpg'
import ImpagePorFolio6 from '../../assets/img/gallery/protfolio-img06.jpg'
import AboutBg from '../../assets/img/bg/about.jpg'
import CIFlag from '../../assets/img/countries/ci.png'
import SNFlag from '../../assets/img/countries/sn.png'
import FRFlag from '../../assets/img/countries/fr.png'

function Main(props) {
  const { name, classname } = props
  var imgSource = null
  var alt = null
  var classN = (classname) ? classname : null
  switch (name) {
    case "LogoBlanc":
      imgSource = LogoBlanc
      classN = "logo"
      alt = "Logo Skills Bridge Academy"
      break
    case "AboutBg":
      imgSource = AboutBg
      break
    case "ImagePhoneCall":
      imgSource = ImagePhoneCall
      break
    case "ImageMailing":
      imgSource = ImageMailing
      break
    case "LogoFooter":
      imgSource = LogoFooter
      break
    case "ImageBg01":
      imgSource = ImageBg01
      break
    case "ImageBg02":
      imgSource = ImageBg02
      break
    case "ImageBg03":
      imgSource = ImageBg03
      break
    case "ImageBg04":
      imgSource = ImageBg04
      break
    case "ImageBg05":
      imgSource = ImageBg05
      break
    case "ImageBg06":
      imgSource = ImageBg06
      break
    case "ImageBg10":
      imgSource = ImageBg10
      break
    case "ImageBg11":
      imgSource = ImageBg11
      break
    case "ImageBg12":
      imgSource = ImageBg12
      break
    case "ImageBg13":
      imgSource = ImageBg13
      break
    case "ImageBg14":
      imgSource = ImageBg14
      break
    case "ImageBg15":
      imgSource = ImageBg15
      break
    case "ImageBg16":
      imgSource = ImageBg16
      break
    case "ImageBg17":
      imgSource = ImageBg17
      break
    case "ImageBg18":
      imgSource = ImageBg18
      break
    case "ImageBg19":
      imgSource = ImageBg19
      break
    case "ImageBg20":
      imgSource = ImageBg20
      break
    case "ImageAvatar":
      imgSource = ImageAvatar;
      break
    case "ImageQtIcon":
      imgSource = ImageQtIcon;
      break
    case "ImageCourse1":
      imgSource = ImageCourse1
      break
    case "ImageCouIcon":
      imgSource = ImageCouIcon
      break
    case "ImageBrandLogo1":
      imgSource = ImageBrandLogo1
      break
    case "ImageBrandLogoBeniking":
      imgSource = ImageBrandLogoBeniking
      break
    case "ImageBrandLogoQualiopi":
      imgSource = ImageBrandLogoQualiopi
      break
    case "ImageBrandLogoFdfp":
      imgSource = ImageBrandLogoFdfp
      break
    case "ImageBrandLogoCames":
      imgSource = ImageBrandLogoCames
      break
    case "ImageBrandLogoPolygloth":
      imgSource = ImageBrandLogoPolygloth
      break
    case "ImageSceIcon1":
      imgSource = ImageSceIcon1
      break
    case "ImageSceIcon2":
      imgSource = ImageSceIcon2
      break
    case "ImageSceIcon3":
      imgSource = ImageSceIcon3
      break
    case "ImageSceIcon7":
      imgSource = ImageSceIcon7
      break
    case "ImageSceIcon8":
      imgSource = ImageSceIcon8
      break
    case "ImageSceIcon9":
      imgSource = ImageSceIcon9
      break
    case "ImageSceIcon10":
      imgSource = ImageSceIcon10
      break
    case "ImageBlog1":
      imgSource = ImageBlog1
      break
    case "ImageBlog2":
      imgSource = ImageBlog2
      break
    case "ImageInnerb1":
      imgSource = ImageInnerb1
      break
    case "ImageInnerb2":
      imgSource = ImageInnerb2
      break
    case "ImageInnerb3":
      imgSource = ImageInnerb3
      break
    case "ImageInnerb4":
      imgSource = ImageInnerb4
      break
    case "ImageInnerb5":
      imgSource = ImageInnerb5
      break
    case "ImageInnerb6":
      imgSource = ImageInnerb6
      break
    case "ImageInnerb7":
      imgSource = ImageInnerb7
      break
    case "ImageCouress02":
      imgSource = ImageCouress02
      break
    case "ImageCouress03":
      imgSource = ImageCouress03
      break
    case "ImageCouress04":
      imgSource = ImageCouress04
      break
    case "ImageCouress05":
      imgSource = ImageCouress05
      break
    case "ImageCouress06":
      imgSource = ImageCouress06
      break
    case "ImageAbout2":
      imgSource = ImageAbout2
      break
    case "ImageAbout3":
      imgSource= ImageAbout3
      break
    case "ImageAbout4":
      imgSource = ImageAbout4
      break
    case "ImageAbout5":
      imgSource = ImageAbout5
      break
    case "ImageBgSteps":
      imgSource = ImageBgSteps
      break
    case "ImageFeaIcon01":
      imgSource = ImageFeaIcon01
      break
    case "ImageFeaIcon02":
      imgSource = ImageFeaIcon02
      break
    case "ImageFeaIcon03":
      imgSource = ImageFeaIcon03
      break
    case "ImagePlayButton":
      imgSource = ImagePlayButton
      break
    case "ImagePlayButton2":
      imgSource = ImagePlayButton2
      break
    case "ImageCtaBg":
      imgSource = ImageCtaBg
      break
    case "ImageStepBg01":
      imgSource = ImageStepBg01
      break
    case "ImageClass1":
      imgSource = ImageClass1
      break
    case "ImageClass2":
      imgSource = ImageClass2
      break
    case "ImageClass3":
      imgSource = ImageClass3
      break
    case "ImageClass4":
      imgSource = ImageClass4
      break
    case "ImageClass5":
      imgSource = ImageClass5
      break
    case "ImageClass6":
      imgSource = ImageClass6
      break
    case "ImageClass7":
      imgSource = ImageClass7
      break
    case "ImageClass8":
      imgSource = ImageClass8
      break
    case "ImageClass9":
      imgSource = ImageClass9
      break
    case "ImageClass10":
      imgSource = ImageClass10
      break
    case "ImgaeStar":
      imgSource = ImgaeStar
      break
    case "ImageContactIcon1":
      imgSource = ImageContactIcon1
      break
    case "ImageContactIcon2":
      imgSource = ImageContactIcon2
      break
    case "ImageContactIcon3":
      imgSource = ImageContactIcon3
      break
    case "Image404":
      imgSource = Image404
      break
    case "ImageTeam1":
      imgSource = ImageTeam1;
      break
    case "ImageTeam2":
      imgSource = ImageTeam2
      break
    case "ImageTeam3":
      imgSource = ImageTeam3
      break
    case "ImageTeam4":
      imgSource = ImageTeam4
      break
    case "ImageTeam5":
      imgSource = ImageTeam5
      break
    case "ImageTeam9":
      imgSource = ImageTeam9
      break
    case "ImageTeam10":
      imgSource = ImageTeam10
      break
    case "ImageTeam11":
      imgSource = ImageTeam11
      break
    case "ImageTeam12":
      imgSource = ImageTeam12
      break
    case "ImageBgEnv01":
      imgSource = ImageBgEnv01
      break
    case "ImageBgEnv02":
      imgSource = ImageBgEnv02
      break
    case "ImageBgEnv03":
      imgSource = ImageBgEnv03
      break
    case "ImageBgEnv04":
      imgSource = ImageBgEnv04
      break
    case "ImageBgEnv05":
      imgSource = ImageBgEnv05
      break
    case "ImageBgEnv06":
      imgSource = ImageBgEnv06
      break
    case "ImpagePorFolio1":
      imgSource = ImpagePorFolio1
      break
    case "ImpagePorFolio2":
      imgSource = ImpagePorFolio2
      break
    case "ImpagePorFolio3":
      imgSource = ImpagePorFolio3
      break
    case "ImpagePorFolio4":
      imgSource = ImpagePorFolio4
      break
    case "ImpagePorFolio5":
      imgSource = ImpagePorFolio5
      break
    case "ImpagePorFolio6":
      imgSource = ImpagePorFolio6
      break
    case "ImageSliderShape1":
      imgSource = ImageSliderShape1
      break
    case "ImageSliderShape2":
      imgSource = ImageSliderShape2
      break
    case "ImageSliderShape3":
      imgSource = ImageSliderShape3
      break
    case "ImageSliderShape4":
      imgSource = ImageSliderShape4
      break
    case "ImageCourseRH":
      imgSource = ImageCourseRH
      classN = (classname) ? classname : "radius2em"
      break
    case "ImageCourseBanque":
      imgSource = ImageCourseBanque
      classN = (classname) ? classname : "radius2em"
      break
    case "ImageCourseCommerce":
      imgSource = ImageCourseCommerce
      classN = (classname) ? classname : "radius2em"
      break
    case "ImageCourseQhse":
      imgSource = ImageCourseQhse
      classN = (classname) ? classname : "radius2em"
      break
    case "ImageCourseManagement":
      imgSource = ImageCourseManagement
      classN = (classname) ? classname : "radius2em"
      break
    case "ImageCourseManagementBachelor":
      imgSource = ImageCourseManagementBachelor
      classN = (classname) ? classname : "radius2em"
      break
    case "ImageAlternance":
      imgSource = ImageAlternance
      classN = (classname) ? classname : "radius2em"
      alt = "Alternance"
      break
    case "ImageAlternanceProA":
      imgSource = ImageAlternanceProA
      classN = "radius2em"
      alt = "Alternance"
      break
    case "ImageAlternanceDuree":
      imgSource = ImageAlternanceDuree
      break
    case "ImageValeurs":
      imgSource = ImageValeurs
      break
    case "ImageEtudianteAdmission":
      imgSource = ImageEtudianteAdmission
      break
    case "ImageEtudiantsDivers":
      imgSource = ImageEtudiantsDivers
      alt = "Étudiants de diverses origines"
      classN = "radius2em"
      break
    case "ImageConditionsProsfibe":
      imgSource = ImageConditionsProsfibe
      alt = "Conditions pour le PROSFIBE"
      break
    case "ImageMobiliteInternationale":
      imgSource = ImageMobiliteInternationale
      alt = "Mobilité internationale"
      classN = "radius2em"
      break
    case "ImageEtudianteBrillante":
      imgSource = ImageEtudianteBrillante
      alt = "Mobilité internationale - Étudiante brillante"
      classN = "radius2em"
      break
    case "ImageCVDossier":
      imgSource = ImageCVDossier
      alt = "Mobilité internationale - Instructions"
      classN = "radius2em"
      break
    case "ImageCreativeLab":
      imgSource = ImageCreativeLab
      alt = "Creative Lab"
      break
    case "ImageDiagnostic":
      imgSource = ImageDiagnostic
      alt = "Diagnostic"
      break
    case "ImageProA":
      imgSource = ImageProA
      alt = "Pro-A"
      break
    case "CIFlag":
      imgSource = CIFlag
      classN = "flag min"
      alt = "Côte d'Ivoire"
      break
    case "FRFlag":
      imgSource = FRFlag
      classN = "flag min"
      alt = "France"
      break
    case "SNFlag":
      imgSource = SNFlag
      classN = "flag min"
      alt = "Sénégal"
      break
    default:
      imgSource = Logo
      alt = Logo
      classN = Logo
      break
  }
  return (
    <img src={imgSource} alt={alt} className={classN}/>
  )
}
export default Main