import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Image from '../../Image/Main'

function First() {

    const Data = [
        
        {img : <Image name= "ImpagePorFolio1" /> , category : "Financial"},
    
        {img : <Image name= "ImpagePorFolio2" />, category : "Financial"},

        {img : <Image name= "ImpagePorFolio2" />, category : "Analyzing"},
    
        {img : <Image name= "ImpagePorFolio3" /> , category : "Insurance"},
    
        {img : <Image name= "ImpagePorFolio4" /> , category : "Family"},

        {img : <Image name= "ImpagePorFolio5" /> , category : "Business"},

        {img : <Image name= "ImpagePorFolio6" />, category : "Financial"},

        {img : <Image name= "ImpagePorFolio6" />, category : "Marketing"},

        {img : <Image name= "ImpagePorFolio1" />, category : "Marketing"},

      ]

      const [current, setcurrent] = useState(Data);

      const Filter = (category) =>{
          const arr = [];
          if(category === "View All"){
          setcurrent(Data)
          }
          else{
          Data.map((item)=> {
              if(item.category === category){
              arr.push(item);
              }
          })
          setcurrent(arr);
          }
      };


  return (
    <>
        <section id="work" className="pt-150 pb-105">
            <div className="container">
                <div className="portfolio ">
                <div className="row align-items-end">
                    <div className="col-lg-12">
                    <div className="my-masonry text-center mb-50 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                        <div className="button-group filter-button-group ">
                        <button className="active" data-filter="*" onClick={()=> Filter("View All")} > View All </button>
                        <button data-filter=".financial" onClick={()=> Filter("Financial")} >Financial</button>
                        <button data-filter=".banking" onClick={()=> Filter("Analyzing")} >Analyzing</button>
                        <button data-filter=".insurance" onClick={()=> Filter("Marketing")} >Marketing </button>
                        <button data-filter=".family" onClick={()=> Filter("Business")} >Business</button>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="grid col3 row wow fadeInUp  animated" data-animation="fadeInUp" data-delay=".4s" >
                    {current.map( (item)=> ( 
                        <div className="grid-item financial col-4">
                            <Link to="/single-projects">
                                <figure className="gallery-image">
                                    {item.img}
                                </figure>
                            </Link>
                        </div>
                    ))}
                </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default First