import React from 'react'
import { Link } from 'react-router-dom'
import Image from '../Image/Main'

function Goging() {
  return (
    <>
        <section className="class-area pt-120 pb-120 p-relative fix">
            <div className="animations-06">
                <Image name="ImageBg11" />
            </div>
            <div className="animations-11">
                <Image name="ImageBg01" />
            </div>
            <div className="animations-12">
                <Image name="ImageBg12" />
            </div>
            <div className="animations-13">
                <Image name="ImageBg13" />
            </div>
            <div className="container">
                <div className="row">
                <div className="col-lg-12 col-md-12">
                    <div className="section-title text-center mb-50">
                    <h5>On Going Classes</h5>
                    <h2>Get The Best Classes With Us</h2>
                    </div>
                </div>
                </div>
                <div className="row justify-content-center">
                <div className="col-xl-4 col-lg-4 col-md-6">
                    <div className="class-item mb-30 hover-zoomin">
                    <div className="class-img">
                        <div className="class-img-outer">
                        <Link to="/single-courses">
                            {" "}
                            <Image name="ImageClass1" />
                        </Link>
                        </div>
                    </div>
                    <div className="class-content">
                        <h4 className="title">
                        <Link to="/single-courses">Languge Class</Link>
                        </h4>
                        <p>
                        Seamlessly visualize quality ellectual capital without superior
                        collaboration and idea.
                        </p>
                        <ul className="schedule">
                        <li>
                            <span>Age:</span>
                            <span className="class-age">5-10 Years</span>
                        </li>
                        <li>
                            <span>Time:</span>
                            <span className="class-size">8-10am</span>
                        </li>
                        <li>
                            <span>Class Size:</span>
                            <span className="class-size">28</span>
                        </li>
                        <li>
                            <span>Fee:</span>
                            <span className="class-size">$50</span>
                        </li>
                        </ul>
                    </div>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6">
                    <div className="class-item mb-30 hover-zoomin">
                    <div className="class-img">
                        <div className="class-img-outer">
                        <Link to="/single-courses">
                            {" "}
                            <Image name="ImageClass2" />
                        </Link>
                        </div>
                    </div>
                    <div className="class-content">
                        <h4 className="title">
                        <Link to="/single-courses">Drawing Class</Link>
                        </h4>
                        <p>
                        Seamlessly visualize quality ellectual capital without superior
                        collaboration and idea.
                        </p>
                        <ul className="schedule">
                        <li>
                            <span>Age:</span>
                            <span className="class-age">5-10 Years</span>
                        </li>
                        <li>
                            <span>Time:</span>
                            <span className="class-size">8-10am</span>
                        </li>
                        <li>
                            <span>Class Size:</span>
                            <span className="class-size">28</span>
                        </li>
                        <li>
                            <span>Fee:</span>
                            <span className="class-size">$50</span>
                        </li>
                        </ul>
                    </div>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6">
                    <div className="class-item mb-30 hover-zoomin">
                    <div className="class-img">
                        <div className="class-img-outer">
                        <Link to="/single-courses">
                            <Image name="ImageClass3" />
                        </Link>
                        </div>
                    </div>
                    <div className="class-content">
                        <h4 className="title">
                        <Link to="/single-courses">Mathematics Class</Link>
                        </h4>
                        <p>
                        Seamlessly visualize quality ellectual capital without superior
                        collaboration and idea.
                        </p>
                        <ul className="schedule">
                        <li>
                            <span>Age:</span>
                            <span className="class-age">5-10 Years</span>
                        </li>
                        <li>
                            <span>Time:</span>
                            <span className="class-size">8-10am</span>
                        </li>
                        <li>
                            <span>Class Size:</span>
                            <span className="class-size">28</span>
                        </li>
                        <li>
                            <span>Fee:</span>
                            <span className="class-size">$50</span>
                        </li>
                        </ul>
                    </div>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6">
                    <div className="class-item mb-30 hover-zoomin">
                    <div className="class-img">
                        <div className="class-img-outer">
                        <Link to="/single-courses">
                            <Image name="ImageClass4" />
                        </Link>
                        </div>
                    </div>
                    <div className="class-content">
                        <h4 className="title">
                        <Link to="/single-courses">Sports Class</Link>
                        </h4>
                        <p>
                        Seamlessly visualize quality ellectual capital without superior
                        collaboration and idea.
                        </p>
                        <ul className="schedule">
                        <li>
                            <span>Age:</span>
                            <span className="class-age">5-10 Years</span>
                        </li>
                        <li>
                            <span>Time:</span>
                            <span className="class-size">8-10am</span>
                        </li>
                        <li>
                            <span>Class Size:</span>
                            <span className="class-size">28</span>
                        </li>
                        <li>
                            <span>Fee:</span>
                            <span className="class-size">$50</span>
                        </li>
                        </ul>
                    </div>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6">
                    <div className="class-item mb-30 hover-zoomin">
                    <div className="class-img">
                        <div className="class-img-outer">
                        <Link to="/single-courses">
                            <Image name="ImageClass5" />
                        </Link>
                        </div>
                    </div>
                    <div className="class-content">
                        <h4 className="title">
                        <Link to="/single-courses">Knowlage Class</Link>
                        </h4>
                        <p>
                        Seamlessly visualize quality ellectual capital without superior
                        collaboration and idea.
                        </p>
                        <ul className="schedule">
                        <li>
                            <span>Age:</span>
                            <span className="class-age">5-10 Years</span>
                        </li>
                        <li>
                            <span>Time:</span>
                            <span className="class-size">8-10am</span>
                        </li>
                        <li>
                            <span>Class Size:</span>
                            <span className="class-size">28</span>
                        </li>
                        <li>
                            <span>Fee:</span>
                            <span className="class-size">$50</span>
                        </li>
                        </ul>
                    </div>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6">
                    <div className="class-item mb-30 hover-zoomin">
                    <div className="class-img">
                        <div className="class-img-outer">
                        <Link to="/single-courses">
                            <Image name="ImageClass6" />
                        </Link>
                        </div>
                    </div>
                    <div className="class-content">
                        <h4 className="title">
                        <Link to="/single-courses">Science Class</Link>
                        </h4>
                        <p>
                        Seamlessly visualize quality ellectual capital without superior
                        collaboration and idea.
                        </p>
                        <ul className="schedule">
                        <li>
                            <span>Age:</span>
                            <span className="class-age">5-10 Years</span>
                        </li>
                        <li>
                            <span>Time:</span>
                            <span className="class-size">8-10am</span>
                        </li>
                        <li>
                            <span>Class Size:</span>
                            <span className="class-size">28</span>
                        </li>
                        <li>
                            <span>Fee:</span>
                            <span className="class-size">$50</span>
                        </li>
                        </ul>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default Goging