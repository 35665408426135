import {React, useState, useEffect} from 'react'
import Header from '../headerone/Main'
import Bredcom from '../Bredcom/Main'
import { useLocation, useNavigate, Link } from "react-router-dom"

function Main() {

  const location = useLocation()
  const navigate = useNavigate()

  return (
    <>
        <Header/>
        <Bredcom 
        subtitle="Paiement annulé!"/>
        <section id="candidature" className="services-area pt-120 pb-90 fix" style={{ background: "#e7f0f8" }}>
            <div className="container">
              <div className="row">
                <div className="col-lg-12 order-2">
                  <div className="contact-bg">
                    <div className="section-title center-align text-center mb-50">
                        <h2>Votre paiement a été annulé.</h2>
                        <p>
                            Vous n'avez pas finalisé le paiement des frais de votre pré-inscription.
                        </p>
                        <p className='text-danger'>
                            Votre candidature a été enregistrée mais votre dossier ne sera pas étudié sans le paiement des frais de pré-inscription.
                            <br />
                            Vous serez contactez par le service des admissions afin de procéder au paiement des frais de pré-inscription.
                        </p>
                    </div>
                    <div className="row">
                      <div className="col-3">
                       </div>
                    </div>
                  </div>
                </div>
              </div>
          </div> 
        </section>
    </>
  )
}

export default Main