import React from 'react'
import { Link } from 'react-router-dom'
import Image from '../Image/Main'
import { useState } from 'react'

function BachelorQhse() {
    const [country, setCountry] = useState("ci")
    const [course, setCourse] = useState("b1")
  return (
    <>
        <section className="project-detail">
            <div className="container">
                <div className="lower-content">
                <div className="row">
                    <div className="text-column col-lg-9 col-md-9 col-sm-12">
                    {/*<h2>
                        <span className="text-primary">Bachelor</span><br />
                        Responsable Qualité et QHSE
                    </h2>*/}
                    <div className="upper-box">
                        <div className="single-item-carousel owl-carousel owl-theme">
                        <figure className="image">
                            <Image name="ImageCourseQhse" />
                        </figure>
                        </div>
                    </div>
                    <div className="inner-column">
                        {/*<div className="course-meta2 review style2 clearfix mb-30">
                            <ul className="left">
                                <li>
                                    <div className="author">
                                        <div className="thumb">
                                            <Image name="ImageAvatar" />
                                        </div>
                                        <div className="text">
                                        <Link to="/single-courses-2">Prénom NON</Link>
                                        <p>Enseignant principal</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <ul className="right">
                                <li className="price">LinkedIn</li>
                            </ul>
                        </div>*/}
                        <h3>Présentation</h3>
                        <p>
                        Ce parcours prépare les étudiants à relever les défis liés à la gestion de la qualité, de la sécurité, de la santé et de l’environnement au sein des organisations. Ce programme est conçu pour former des professionnels capables d’assurer la gestion de systèmes QHSE et d'en garantir l’efficacité. Les étudiants apprennent à analyser les risques, à auditer les processus et à implémenter des actions pour améliorer les performances organisationnelles dans différents secteurs d’activité : industrie, services, et autres.
                        </p>
                        <h4>Compétences visées</h4>
                        <ul className="pr-ul">
                            <li>
                                <div className="icon">
                                    <i className="fal fa-check" />
                                </div>
                                <div className="text">
                                    Comprendre les normes de base en matière de QHSE.
                                </div>
                            </li>
                            <li>
                                <div className="icon">
                                    <i className="fal fa-check" />
                                </div>
                                <div className="text">
                                    Participer à la mise en œuvre de systèmes de management qualité et sécurité.
                                </div>
                            </li>
                            <li>
                                <div className="icon">
                                    <i className="fal fa-check" />
                                </div>
                                <div className="text">
                                    Analyser et améliorer les processus internes en matière de qualité et sécurité.
                                </div>
                            </li>
                            <li>
                                <div className="icon">
                                    <i className="fal fa-check" />
                                </div>
                                <div className="text">
                                    Initier des actions de prévention en matière de santé et sécurité au travail.
                                </div>
                            </li>
                        </ul>
                        <h4>Spécificités de la formation</h4>
                        <ul className="pr-ul">
                            <li>
                                <div className="icon">
                                    <i className="fal fa-check" />
                                </div>
                                <div className="text">
                                    En alternance ou en formation initiale.
                                </div>
                            </li>
                            <li>
                                <div className="icon">
                                    <i className="fal fa-check" />
                                </div>
                                <div className="text">
                                    Modules en ligne et en présentiel.
                                </div>
                            </li>
                            <li>
                                <div className="icon">
                                    <i className="fal fa-check" />
                                </div>
                                <div className="text">
                                    Cas pratiques et projets de terrain.
                                </div>
                            </li>
                        </ul>
                        <h4>Conditions d'Accès</h4>
                        <ul className="pr-ul">
                            <li>
                                <div className="icon">
                                    <i className="fal fa-check" />
                                </div>
                                <div className="text">
                                    Accessible après un Bac ou équivalent.
                                </div>
                            </li>
                            <li>
                                <div className="icon">
                                    <i className="fal fa-check" />
                                </div>
                                <div className="text">
                                    Sélection sur dossier et entretien.
                                </div>
                            </li>
                        </ul>
                        <h3>Débouchés professionnels</h3>
                        <p>
                        Chargé de QSE, Assistant QHSE, Auditeur junior, Chargé de la qualité junior.
                        </p>
                    </div>
                    </div>
                    <div className="col-lg-3">
                    <aside className="sidebar-widget info-column">
                        <div className="inner-column3">
                        <h3>Informations</h3>
                        <div className="pd-1">
                            <div className="d-flex justify-content-between">
                                <select value={country} onChange={(e) => setCountry(e.target.value)}>
                                    <option value="ci">Côte d'Ivoire</option>
                                    <option value="fr">France</option>
                                    <option value="sn">Sénégal</option>
                                </select>
                                &nbsp;
                                <select value={course} onChange={(e) => setCourse(e.target.value)}>
                                    <option value="b1">Bachelor 1</option>
                                    <option value="b2">Bachelor 2</option>
                                    <option value="b3">Bachelor 3</option>
                                </select>
                            </div>
                            {(country != "fr") ? 
                                <div className="priceing prix-afrique mt-3">
                                    {(course == "b1") ? <h4 className="text-primary text-center">2.300.000F CFA</h4> : (course == "b2") ? <h4 className="text-primary text-center">3.000.000F CFA</h4> : <h4 className="text-primary text-center">3.800.000F CFA</h4>}
                                </div> 
                                :
                                <div className="priceing prix-france mt-3">
                                    {(course == "b1") ? <h4 className="text-primary text-center">6.500€</h4> : (course == "b2") ? <h4 className="text-primary text-center">6.500€</h4> : <h4 className="text-primary text-center">7.500€</h4>}
                                </div>
                            }
                            <div className="text-center">Pré-inscription : {(country != "fr") ? "100.000F CFA" : "150€"}*</div>
                        </div>
                        <ul className="project-info clearfix">
                            <li>
                                <span className="icon fal fa-user-graduate" />{" "}
                                <strong>Diplôme: </strong> <span>Titre de niveau 6 (BAC +3, BAC +4)</span>
                            </li>
                            <li>
                                <span className="icon fal fa-home-lg-alt" />{" "}
                                <strong>Formation</strong> <span>En alternance</span>
                            </li>
                            <li>
                                <span className="icon fal fa-book" />{" "}
                                <strong>Pré-requis:</strong> 
                                <span>{(course == "b1") ? "Bac validé": (course == "b2") ? "Bachelor 1 ou Licence 1" : "Bachelor 2 ou Licence 2 ou BTS validé"}</span>
                            </li>
                            <li>
                                <span className="icon fal fa-clock" />{" "}
                                <strong>Durée: </strong> 
                                    <span>{(course == "b1") ? "3 ans": (course == "b2") ? "2 ans" : "1 an"}</span>
                            </li>
                            <li className="hide">
                                <span className="icon fal fa-user" />{" "}
                                <strong>Classe: </strong> <span>20 étudiants</span>
                            </li>
                            <li>
                                <span className="icon fal fa-edit" />{" "}
                                <strong>Contrat: </strong> <span>Apprentissage et professionnalisation</span>
                            </li>
                            <li>
                                <div className="slider-btn">
                                    <Link to={`/candidature?country=${country}&level=${course}&course=qhse`} className="btn ss-btn smoth-scroll">
                                        S'inscrire <i className="fal fa-long-arrow-right" />
                                    </Link>
                                </div>
                            </li>
                        </ul>
                        </div>
                    </aside>
                    </div>
                </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default BachelorQhse