import React from 'react'
import Bredcom from '../Bredcom/Main'
import Header from '../headerone/Main'
import MasterComMarkCo from '../mencoursedetails/MasterComMarkCo'
import Courses from '../menhome/Courses'
import Brand from '../menhomethree/Brand'

function Main() {
  return (
    <>
        <Header/>
        <Bredcom 
          title="Masters" 
          titleLink="/courses/masters" 
          subtitle="Master - Commerce, Marketing et Communication" />
        <MasterComMarkCo/>
        <Courses/>
    </>
  )
}

export default Main