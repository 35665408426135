import { React, useState, useEffect } from "react";
import Header from "../headerone/Main";
import Bredcom from "../Bredcom/Main";
import { Link } from "react-router-dom";
import CheckoutButton from "./CheckoutButton";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Image from "../Image/Main";
import axios from "axios";
import Swal from "sweetalert2";

function Main() {
  /*useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://cdn.cinetpay.com/seamless/main.js";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      if (window.CinetPay) {
        // Logique ou initialisation avec window.CinetPay ici
        //console.log("CinetPay est chargé :", window.CinetPay);
      } else {
       // console.error("CinetPay n'est pas disponible.");
      }
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);*/

  // recreating the `Stripe` object on every render.
  const stripePromise = loadStripe(
    "pk_live_51Q7atd07Ei4E6VYsgMP7cYNl4hOMdxDcMKA8Iqq8sDEOUQ1IrSr9nfsC45KAI0kI1eg7rYciy4kQWX8fhnr9MRYE00ZfQj50Z6"
  );
  const options = {
    // passing the client secret obtained from the server
    clientSecret:
      "pi_3MtwBwLkdIwHu7ix28a3tqPa_secret_YrKJUKribcBjcG8HVhfZluoGH",
  };

  // Informations sur la transaction
  const [transactionId] = useState(() => {
    return Math.floor(1000000000 + Math.random() * 9000000000);
  });
  const [amount, setAmount] = useState(153000);
  const [paymentMethod, setPaymentMethod] = useState("CinetPay");
  const [description, setDescription] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("waiting");
  const [currency, setCurrency] = useState("XOF");
  const [pid, setPid] = useState(null); // id de la transaction dans la base de données

  const [step, setStep] = useState("/candidature");

  // variables du formulaire
  const [uid, setUid] = useState(null);
  const [gender, setGender] = useState("homme");
  const [firstname, setFirstname] = useState(null);
  const [lastname, setLastname] = useState(null);
  const [birthdate, setBirthdate] = useState(null);
  const [address, setAddress] = useState(null);
  const [city, setCity] = useState(null);
  const [email, setEmail] = useState(null);
  const [phone, setPhone] = useState(null);
  const [country, setCountry] = useState("ci");
  const [level, setLevel] = useState("b1");
  const [course, setCourse] = useState("RH et Management du capital humain");
  const [lastdiploma, setLastdiploma] = useState(null);
  const [lastdiplomadetails, setLastdiplomadetails] = useState(null);
  const [lastlevel, setLastlevel] = useState(null);
  const [lastdiplomaFile, setLastdiplomaFile] = useState(null);
  const [bulletinsFiles, setBulletinsFiles] = useState([]);
  const [certificateFile, setCertificateFile] = useState(null);
  const [cv, setCv] = useState(null);
  const [motivation, setMotivation] = useState(null);

  const activeStep = (path) => {
    return path === step ? true : false;
  };
  const getLinkClass = (path) => {
    return "nav-link " + (path === step ? "active" : undefined);
  };

  const changePage = async (data) => {
    if (
      data !== "/candidature" &&
      data !== "/candidature/informations-personnelles"
    ) {
      if (firstname === null) {
        Swal.fire({
          toast: true,
          title: "Veuillez renseigner votre prénom",
          icon: "error",
          showConfirmButton: false,
          showCloseButton: true,
          position: "top-right",
          timer: 5000,
        });
        return false;
      } else if (lastname === null) {
        Swal.fire({
          toast: true,
          title: "Veuillez renseigner votre nom",
          icon: "error",
          showConfirmButton: false,
          showCloseButton: true,
          position: "top-right",
          timer: 5000,
        });
        return false;
      } else if (birthdate === null) {
        Swal.fire({
          toast: true,
          title: "Veuillez renseigner votre date de naissance",
          icon: "error",
          showConfirmButton: false,
          showCloseButton: true,
          position: "top-right",
          timer: 5000,
        });
        return false;
      } else if (email === null || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
        Swal.fire({
          toast: true,
          title: "Renseignez une adresse email valide",
          icon: "error",
          showConfirmButton: false,
          showCloseButton: true,
          position: "top-right",
          timer: 5000,
        });
        return false;
      } else if (
        phone === null ||
        !/^(?=.*\d.*\d.*\d.*\d.*\d.*\d.*\d.*\d)[0-9\s]*$/.test(phone)
      ) {
        Swal.fire({
          toast: true,
          title: "Renseignez un numéro de téléphone correct",
          icon: "error",
          showConfirmButton: false,
          showCloseButton: true,
          position: "top-right",
          timer: 5000,
        });
        return false;
      }
    }
    setStep(data);
  };

  // mettre à jour les informations de paiement
  const updatePaymentInfos = () => {
    setAmount(153);
    setCurrency("EUR");
    setPaymentMethod("Stripe");
    // à décommenter quand on va utiliser Stripe
    /*if (country === "fr") {
      setAmount(153)
      setCurrency('EUR')
      setPaymentMethod('Stripe')
    } else {
      setAmount(100000)
      setCurrency('XOF')
      setPaymentMethod('CinetPay')
    }*/

    let levelDetails =
      level === "b1"
        ? "Bachelor 1"
        : level === "b2"
        ? "Bachelor 2"
        : level === "b3"
        ? "Bachelor 3"
        : level === "m1"
        ? "Master 1"
        : "Master 2";
    setDescription((levelDetails || "") + " : " + (course || ""));

    //console.log(country, amount, currency, paymentMethod, description)
  };

  useEffect(() => {
    updatePaymentInfos();
  }, [country, level, course]);

  // vérification du poids des fichiers
  const checkFileSize = (f, t, fileIndex) => {
    // Taille maximale en octets (10 Mo)
    const maxSize = 10 * 1024 * 1024;

    let good = true;

    if (f && f.size > maxSize) {
      Swal.fire({
        toast: true,
        title: "Fichier trop volumineux",
        text: "Veuillez sélectionner un fichier de moins de 10 Mo.",
        icon: "error",
        showConfirmButton: false,
        showCloseButton: true,
        position: "top-right",
        timer: 5000,
      });
      good = false;
    }

    if (good) {
      switch (t) {
        case "lastdiplomaFile":
          setLastdiplomaFile(f);
          break;
        case "certificateFile":
          setCertificateFile(f);
          break;
        case "bulletinsFiles":
          setBulletinsFiles((prevFiles) => {
            const updatedFiles = [...prevFiles];
            updatedFiles[fileIndex] = f;
            return updatedFiles.slice(0, 2); // Limite à 2 fichiers
          });
          break;
        case "cv":
          setCv(f);
          break;
        case "motivation":
          setMotivation(f);
          break;
        default:
          break;
      }
    } else {
      switch (t) {
        case "lastdiplomaFile":
          document.getElementById("lastdiplomaFile").value = "";
          break;
        case "certificateFile":
          document.getElementById("certificate").value = "";
          break;
        case "bulletinsFiles":
          fileIndex === 0
            ? (document.getElementById("BulletinsFiles1").value = "")
            : (document.getElementById("BulletinsFiles2").value = "");
          break;
        case "cv":
          document.getElementById("cv").value = "";
          break;
        case "motivation":
          document.getElementById("motivation").value = "";
          break;
        default:
          break;
      }
    }
  };

  // Enregistrer la candidature puis ouvrir le module de paiement
  const saveAdmission = async (e) => {
    const formData = new FormData();

    // vérification des champs
    if (firstname === null)
      Swal.fire({
        toast: true,
        title: "Veuillez renseigner votre prénom",
        icon: "error",
        showConfirmButton: false,
        showCloseButton: true,
        position: "top-right",
        timer: 5000,
      });
    else if (lastname === null)
      Swal.fire({
        toast: true,
        title: "Veuillez renseigner votre nom",
        icon: "error",
        showConfirmButton: false,
        showCloseButton: true,
        position: "top-right",
        timer: 5000,
      });
    else if (birthdate === null)
      Swal.fire({
        toast: true,
        title: "Veuillez renseigner votre date de naissance",
        icon: "error",
        showConfirmButton: false,
        showCloseButton: true,
        position: "top-right",
        timer: 5000,
      });
    else if (email === null || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email))
      Swal.fire({
        toast: true,
        title: "Renseignez une adresse email valide",
        icon: "error",
        showConfirmButton: false,
        showCloseButton: true,
        position: "top-right",
        timer: 5000,
      });
    else if (
      phone === null ||
      !/^(?=.*\d.*\d.*\d.*\d.*\d.*\d.*\d.*\d)[0-9\s]*$/.test(phone)
    )
      Swal.fire({
        toast: true,
        title: "Renseignez un numéro de téléphone correct",
        icon: "error",
        showConfirmButton: false,
        showCloseButton: true,
        position: "top-right",
        timer: 5000,
      });
    else {
      formData.append("genre", gender);
      formData.append("prenom", firstname);
      formData.append("nom", lastname);
      formData.append("dateNaissance", birthdate);
      formData.append("paysInscription", country);
      formData.append("adresse", address);
      formData.append("ville", city);
      formData.append("email", email);
      formData.append("telephone", phone);

      // Ajouter les fichiers au FormData
      if (lastdiplomaFile != null)
        formData.append("diplomeFile", lastdiplomaFile);
      if (bulletinsFiles.length > 0)
        formData.append("bulletinsFiles", bulletinsFiles);
      if (certificateFile != null)
        formData.append("certificatScolarite", certificateFile);
      if (cv != null) formData.append("cvFile", cv);
      if (motivation != null) formData.append("lettreMotivation", motivation);

      if (uid === null) {
        try {
          const req = await axios.post(
            "https://api.skillsbridge-academy.com/preregister",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          if (req.data._id) setUid(req.data._id);
          // ne pas oublier de transmettre l'id de la candidature (uid), surtout à Stripe (dans les paramètres d'url de retour)
          //checkout();
        } catch (error) {
          Swal.fire({
            toast: true,
            title: "Quelque chose n'a pas marché.",
            text: error,
            icon: "error",
            showConfirmButton: false,
            showCloseButton: true,
            position: "top-right",
            timer: 3000,
          });
        }
      } else {
        try {
          await axios.put(
            "https://api.skillsbridge-academy.com/preregister/" + uid,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          // ne pas oublier de transmettre l'id de la candidature (uid), surtout à Stripe (dans les paramètres d'url de retour)
          //checkout();
        } catch (error) {
          Swal.fire({
            toast: true,
            title: "Erreur lors de l'envoi des données",
            icon: "error",
            showConfirmButton: false,
            showCloseButton: true,
            position: "top-right",
            timer: 3000,
          });
        }
      }
    }
  };

  // Création de la candidature si l'utilisateur clique sur le bouton suivant de la première étape
  const createAdmission = async (e) => {

    if (uid === null || typeof uid === "undefined") {
      try {
        const req = await axios.post(
          "https://api.skillsbridge-academy.com/preregister",
          {
            genre: gender,
            prenom: firstname,
            nom: lastname,
            dateNaissance: birthdate,
            paysInscription: country,
            adresse: address,
            ville: city,
            email: email,
            telephone: phone,
          }
        );

        if (req.data.data._id) {
          setUid(req.data.data._id);
          setTimeout(() => {
            if (uid !== null && typeof uid !== "undefined") savePayment(); // Préparer le paiement
          }, 1000);
          //if (uid !== null && typeof uid !== "undefined") savePayment(); // Préparer le paiement

          changePage("/candidature/scolarite");
        } else {
          Swal.fire({
            toast: true,
            title: req.data.error,
            icon: "error",
            showConfirmButton: false,
            showCloseButton: true,
            position: "top-right",
            timer: 3000,
          });
        }
      } catch (error) {
        Swal.fire({
          toast: true,
          title: "Erreur lors de l'envoi des données",
          icon: "error",
          showConfirmButton: false,
          showCloseButton: true,
          position: "top-right",
          timer: 3000,
        });
      }
    } else {
      saveAdmission();
    }
  };

  // Enregistrer les informations de paiement
  const savePayment = async (e) => {

    if (pid === null || typeof pid === "undefined") {
      try {
        const req = await axios.post(
          "https://api.skillsbridge-academy.com/transaction",
          {
            idTransaction: transactionId,
            idEtudiant: uid,
            methodePaiement: paymentMethod,
            description: description,
            statut: paymentStatus,
            montant: amount,
            devise: currency,
          }
        );
        //console.log(req)
        setPid(req.data.data._id);
      } catch (error) {
        Swal.fire({
          toast: true,
          title: "Erreur lors de l'envoi des données",
          icon: "error",
          showConfirmButton: false,
          showCloseButton: true,
          position: "top-right",
          timer: 3000,
        });
      }
    } else {
      try {
        const req = await axios.put(
          "https://api.skillsbridge-academy.com/transaction/" + pid,
          {
            idTransaction: transactionId,
            idEtudiant: uid,
            methodePaiement: paymentMethod,
            description: description,
            statut: paymentStatus,
            montant: amount,
            devise: currency,
          }
        );
      } catch (error) {
        Swal.fire({
          toast: true,
          title: "Erreur lors de l'envoi des données",
          icon: "error",
          showConfirmButton: false,
          showCloseButton: true,
          position: "top-right",
          timer: 3000,
        });
      }
    }
  };

  // Enregistrement des documents de manière séparée, cette fonction n'est pas exploitée pour le moment
  const saveDocuments = async (e) => {
    // Créer un objet FormData pour l'envoi des fichiers et des autres données
    const formData = new FormData();

    // Ajouter les fichiers au FormData
    formData.append("diplomeFile", lastdiplomaFile);
    formData.append("bulletinsFiles", bulletinsFiles);
    formData.append("certificatScolarite", certificateFile);

    if (uid !== null) {
      try {
        const req = await axios.put(
          "https://api.skillsbridge-academy.com/preregister/" + uid,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        changePage("/candidature/cv-lettre-de-motivation");
      } catch (error) {
        Swal.fire({
          toast: true,
          title: "Erreur lors de l'envoi des données",
          icon: "error",
          showConfirmButton: false,
          showCloseButton: true,
          position: "top-right",
          timer: 3000,
        });
      }
    }
  };

  // Enregistrement du CV et de la lettre de motivation de manière séparée, cette fonction n'est pas exploitée pour le moment
  const saveCvMotivation = async (e) => {
    // Créer un objet FormData pour l'envoi des fichiers et des autres données
    const formData = new FormData();

    // Ajouter les fichiers au FormData
    formData.append("cvFile", cv);
    formData.append("lettreMotivation", motivation);

    if (uid !== null) {
      try {
        const req = await axios.put(
          "https://api.skillsbridge-academy.com/preregister/" + uid,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        changePage("/candidature/paiement");
      } catch (error) {
        Swal.fire({
          toast: true,
          title: "Erreur lors de l'envoi des données",
          icon: "error",
          showConfirmButton: false,
          showCloseButton: true,
          position: "top-right",
          timer: 3000,
        });
      }
    }
  };

  // Pour stocker les 2 bulletins demandés, la fonction n'est plus utilisée mais peut être utile, on garde pour le moment
  const handleBulletinsFileChange = (event, fileIndex) => {
    const selectedFile = event.target.files[0]; // Sélectionne le premier fichier uniquement
    if (!selectedFile) return; // Vérifie qu'un fichier a bien été sélectionné

    setBulletinsFiles((prevFiles) => {
      // Remplace le fichier à l'index correspondant, sinon ajoute un nouveau fichier
      const updatedFiles = [...prevFiles];
      updatedFiles[fileIndex] = selectedFile;
      return updatedFiles.slice(0, 2); // Limite à 2 fichiers
    });
  };

  // Le paiement avec CinetPay, il y a des ajustements à faire, notamment au niveau de la manière d'afficher les messages, et aussi la cordination avec notre base de données
  /*const checkout = () => {
      window.CinetPay.setConfig({
          apikey: '4556519976720a3ce1eb616.14533775',//   YOUR APIKEY
          site_id: '5882230',//YOUR_SITE_ID
          notify_url: 'https://skillsbridge-academy.com/candidature/paiement/notify/',
          mode: 'PRODUCTION'
      });
      window.CinetPay.getCheckout({
          transaction_id: Math.floor(Math.random() * 100000000).toString(), // YOUR TRANSACTION ID
          amount: 100000,
          currency: 'XOF',
          channels: 'ALL',
          description: 'Paiement pré-inscription',   
          //Fournir ces variables pour le paiements par carte bancaire
          customer_name: lastname,//Le nom du client
          customer_surname: firstname,//Le prenom du client
          customer_email: email,//l'email du client
          customer_phone_number: phone,//l'email du client
          customer_address : address,//addresse du client
          customer_city: city,// La ville du client
          customer_country : country.toLocaleUpperCase(),// le code ISO du pays
          customer_state : country.toLocaleUpperCase(),// le code ISO l'état
          //customer_zip_code : "06510", // code postal

      });
      window.CinetPay.waitResponse(function(data) {
          if (data.status == "REFUSED") {
              if (alert("Votre paiement a échoué")) {
                  window.location.reload();
              }
          } else if (data.status == "ACCEPTED") {
              if (alert("Votre paiement a été effectué avec succès")) {
                  window.location.reload();
              }
          }
      });
      window.CinetPay.onError(function(data) {
          //console.log(data);
      });
  }*/

  return (
    <>
      <Header />
      <Bredcom subtitle="Admission" />
      <section
        id="candidature"
        className="services-area pt-120 pb-90 fix"
        style={{ background: "#e7f0f8" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 order-2">
              <div className="contact-bg">
                <div className="section-title center-align text-center mb-50">
                  <h2>Je dépose ma candidature en ligne</h2>
                </div>
                <div className="row">
                  <div className="col-3 m-hide">
                    {/*<Stepper />*/}
                    <nav className="stepper navbar navbar-expand-lg">
                      <div className="navbar-collapse collapse">
                        <ol className="navbar-nav steps">
                          <li className="step nav-item">
                            <Link
                              to="/candidature"
                              className={
                                getLinkClass("/candidature") ||
                                getLinkClass(
                                  "/candidature/informations-personnelles"
                                )
                              }
                              onClick={(e) => {
                                e.preventDefault();
                                changePage("/candidature");
                              }}
                            >
                              <i className="circle">1</i> Informations
                              personnelles
                            </Link>
                          </li>
                          <li className="step nav-item">
                            <Link
                              to="/candidature/scolarite"
                              className={getLinkClass("/candidature/scolarite")}
                              onClick={(e) => {
                                e.preventDefault();
                                changePage("/candidature/scolarite");
                              }}
                            >
                              <i className="circle">2</i> Scolarité
                            </Link>
                          </li>
                          <li className="step nav-item">
                            <Link
                              to="/candidature/documents"
                              className={getLinkClass("/candidature/documents")}
                              onClick={(e) => {
                                e.preventDefault();
                                changePage("/candidature/documents");
                              }}
                            >
                              <i className="circle">3</i> Documents
                            </Link>
                          </li>
                          <li className="step nav-item">
                            <Link
                              to="/candidature/cv-lettre-de-motivation"
                              className={getLinkClass(
                                "/candidature/cv-lettre-de-motivation"
                              )}
                              onClick={(e) => {
                                e.preventDefault();
                                changePage(
                                  "/candidature/cv-lettre-de-motivation"
                                );
                              }}
                            >
                              <i className="circle">4</i> CV & Lettre de
                              motivation
                            </Link>
                          </li>
                          <li className="step nav-item">
                            <Link
                              to="/candidature/paiement"
                              className={getLinkClass("/candidature/paiement")}
                              onClick={(e) => {
                                e.preventDefault();
                                changePage("/candidature/paiement");
                              }}
                            >
                              <i className="circle">5</i> Paiement
                            </Link>
                          </li>
                        </ol>
                      </div>
                    </nav>
                  </div>
                  <div className="col-9 card pd-2 radius1em">
                    {activeStep("/candidature") ||
                    activeStep("/candidature/informations-personnelles") ? (
                      <form>
                        <h4 className="mb-1">Informations personnelles</h4>
                        <div className="row">
                          <div className="col-lg-2">
                            <label htmlFor="gender">Genre *</label>
                            <div className="contact-field p-relative mb-30">
                              <select
                                type="text"
                                id="gender"
                                name="gender"
                                value={gender}
                                onChange={(e) => setGender(e.target.value)}
                                requied
                              >
                                <option value="homme">Homme</option>
                                <option value="femme">Femme</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <label htmlFor="firstname">Prénom *</label>
                            <div className="contact-field p-relative c-name mb-30">
                              <input
                                type="text"
                                id="firstname"
                                name="firstname"
                                placeholder="Prénom"
                                onChange={(e) => setFirstname(e.target.value)}
                                value={firstname}
                                required
                              />
                              <i className="icon fal fa-user" />
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <label htmlFor="lastname">Nom *</label>
                            <div className="contact-field p-relative c-name mb-30">
                              <input
                                type="text"
                                id="lastname"
                                name="lastname"
                                placeholder="Nom"
                                onChange={(e) => setLastname(e.target.value)}
                                value={lastname}
                                required
                              />
                              <i className="icon fal fa-user" />
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <label htmlFor="birthdate">
                              Date de naissance *
                            </label>
                            <div className="contact-field p-relative c-subject mb-30">
                              <input
                                type="date"
                                id="birthdate"
                                name="birthdate"
                                placeholder="Date de naissance"
                                value={birthdate}
                                onChange={(e) => setBirthdate(e.target.value)}
                                required
                              />
                              <i className="icon fal fa-calendar" />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          {/*<div className="col-lg-3">
                                    <label htmlFor="department">Département de naissance</label>
                                    <div className="contact-field p-relative mb-30">
                                        <input type="text" id="department" name="department" placeholder="" />
                                        <i className="icon fal fa-map-marker" />
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <label htmlFor="birthplace">Ville/Commune de naissance</label>
                                    <div className="contact-field p-relative c-name mb-30">
                                        <input type="text" id="birthplace" name="birthplace" placeholder="Ville de naissance" required />
                                        <i className="icon fal fa-map-marker" />
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <label htmlFor="nationality">Nationalité</label>
                                    <div className="contact-field p-relative c-subject mb-30">
                                        <input type="text" id="nationality" name="nationality" placeholder="" required />
                                    <i className="icon fal fa-flag" />
                                    </div>
                                </div>*/}
                          <div className="col-lg-3">
                            <label htmlFor="country">
                              Pays d'inscription *
                            </label>
                            <div className="contact-field p-relative c-subject mb-30">
                              <select
                                id="country"
                                value={country}
                                onChange={(e) => setCountry(e.target.value)}
                              >
                                <option value="ci">Côte d'Ivoire</option>
                                <option value="fr">France</option>
                                <option value="sn">Sénégal</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <label htmlFor="address">Adresse</label>
                            <div className="contact-field p-relative c-name mb-30">
                              <input
                                type="text"
                                id="address"
                                name="address"
                                placeholder="Adresse"
                                onChange={(e) => setAddress(e.target.value)}
                                value={address}
                              />
                              <i className="icon fal fa-map-marker" />
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <label htmlFor="city">Ville</label>
                            <div className="contact-field p-relative c-name mb-30">
                              <input
                                type="text"
                                id="city"
                                name="city"
                                placeholder="Ville"
                                onChange={(e) => setCity(e.target.value)}
                                value={city}
                              />
                              <i className="icon fal fa-map-marker" />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-3">
                            <label htmlFor="email">Adresse email *</label>
                            <div className="contact-field p-relative c-subject mb-30">
                              <input
                                type="email"
                                id="email"
                                name="email"
                                placeholder="Email"
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                                required
                              />
                              <i className="icon fal fa-envelope" />
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <label htmlFor="phone">Téléphone *</label>
                            <div className="contact-field p-relative c-subject mb-30">
                              <input
                                type="phone"
                                id="phone"
                                name="phone"
                                placeholder="Téléphone"
                                onChange={(e) => setPhone(e.target.value)}
                                value={phone}
                                required
                              />
                              <i className="icon fal fa-phone" />
                            </div>
                          </div>
                        </div>
                        <div className="mt-2 d-flex justify-content-end">
                          <button
                            className="btn ss-btn"
                            data-animation="fadeInRight"
                            data-delay=".8s"
                            onClick={(e) => {
                              e.preventDefault();
                              createAdmission();
                            }}
                          >
                            Suivant <i className="fal fa-long-arrow-right" />
                          </button>
                        </div>
                      </form>
                    ) : null}
                    {activeStep("/candidature/scolarite") ? (
                      <form>
                        <h4 className="mb-1">Scolarité</h4>
                        <div className="row mt-5">
                          <div className="col-lg-3">
                            <label htmlFor="lastdiploma">
                              Dernier diplôme ou titre préparé
                            </label>
                            <div className="contact-field p-relative c-name mb-30">
                              <input
                                type="text"
                                id="lastdiploma"
                                name="lastdiploma"
                                placeholder="Ex.: BAC"
                                onChange={(e) => setLastdiploma(e.target.value)}
                                value={lastdiploma}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <label htmlFor="lastdiplomadetails">
                              Intitulé précis du dernier diplôme où titre
                              préparé
                            </label>
                            <div className="contact-field p-relative c-name mb-30">
                              <input
                                type="text"
                                id="lastdiplomadetails"
                                name="lastdiplomadetails"
                                placeholder="Ex.: Baccalauréat scientifique"
                                onChange={(e) =>
                                  setLastdiplomadetails(e.target.value)
                                }
                                value={lastdiplomadetails}
                              />
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <label htmlFor="birthdate">
                              Dernière classe ou année suivie
                            </label>
                            <div className="contact-field p-relative c-subject mb-30">
                              <input
                                type="text"
                                id="lastlevel"
                                name="lastlevel"
                                placeholder="Ex.: Tle"
                                onChange={(e) => setLastlevel(e.target.value)}
                                value={lastlevel}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-lg-4">
                            <label htmlFor="lastdiploma">
                              Niveau d'entrée à SBA souhaité
                            </label>
                            <div className="contact-field p-relative c-name mb-30">
                              <select
                                value={level}
                                onChange={(e) => setLevel(e.target.value)}
                              >
                                <option value="b1">
                                  Bachelor 1 (Tronc commun)
                                </option>
                                <option value="b2">Bachelor 2</option>
                                <option value="b3">Bachelor 3</option>
                                <option value="m1">Master 1</option>
                                <option value="m2">Master 2</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-8">
                            <label htmlFor="lastdiplomadetails">
                              Quel parcours voulez-vous suivre ?
                            </label>
                            <div className="contact-field p-relative c-name mb-30">
                              <select
                                value={course}
                                onChange={(e) => setCourse(e.target.value)}
                              >
                                <option>
                                  RH et Management du capital humain
                                </option>
                                <option>
                                  Management des organisations et des unités
                                  opérationnelles
                                </option>
                                <option>
                                  Commerce, Marketing et Communication
                                </option>
                                <option>Management de la qualité, QHSE</option>
                                <option>Banque, Assurance et Fintech</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="mt-2 d-flex justify-content-between">
                          <button
                            className="btn ss-btn"
                            data-animation="fadeInRight"
                            data-delay=".8s"
                            onClick={(e) => {
                              e.preventDefault();
                              changePage("/candidature");
                            }}
                          >
                            <i className="fal fa-long-arrow-left" /> Précédent
                          </button>
                          <button
                            className="btn ss-btn"
                            data-animation="fadeInRight"
                            data-delay=".8s"
                            onClick={(e) => {
                              e.preventDefault();
                              changePage("/candidature/documents");
                            }}
                          >
                            Suivant <i className="fal fa-long-arrow-right" />
                          </button>
                        </div>
                      </form>
                    ) : null}
                    {activeStep("/candidature/documents") ? (
                      <form>
                        <h4 className="mb-1">Documents justificatifs</h4>
                        <div className="row mt-5">
                          <div className="col-lg-3">
                            <label htmlFor="lastdiplomaFile">
                              Dernier diplôme
                            </label>
                            <div className="contact-field p-relative c-name mb-30">
                              <input
                                type="file"
                                id="lastdiplomaFile"
                                accept=".pdf, .jpg, .jpeg, .png"
                                onChange={(event) =>
                                  checkFileSize(
                                    event.target.files[0],
                                    "lastdiplomaFile"
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <label htmlFor="BulletinsFiles1">
                              2 derniers bulletins scolaires
                            </label>
                            <div className="contact-field p-relative c-name mb-30 d-flex">
                              <input
                                type="file"
                                id="BulletinsFiles1"
                                accept=".pdf, .jpg, .jpeg, .png"
                                onChange={(event) =>
                                  checkFileSize(
                                    event.target.files[0],
                                    "bulletinsFiles",
                                    0
                                  )
                                }
                                className="mr-1"
                              />
                              <input
                                type="file"
                                id="BulletinsFiles2"
                                accept=".pdf, .jpg, .jpeg, .png"
                                onChange={(event) =>
                                  checkFileSize(
                                    event.target.files[0],
                                    "bulletinsFiles",
                                    1
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <label htmlFor="certificate">
                              Certificat ou attestation de scolarité
                            </label>
                            <div className="contact-field p-relative c-subject mb-30">
                              <input
                                type="file"
                                id="certificate"
                                accept=".pdf, .jpg, .jpeg, .png"
                                onChange={(event) =>
                                  setCertificateFile(event.target.files[0])
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="mt-2 d-flex justify-content-between">
                          <button
                            className="btn ss-btn"
                            data-animation="fadeInRight"
                            data-delay=".8s"
                            onClick={(e) => {
                              e.preventDefault();
                              changePage("/candidature/scolarite");
                            }}
                          >
                            <i className="fal fa-long-arrow-left" /> Précédent
                          </button>
                          <button
                            className="btn ss-btn"
                            data-animation="fadeInRight"
                            data-delay=".8s"
                            onClick={(e) => {
                              e.preventDefault();
                              changePage(
                                "/candidature/cv-lettre-de-motivation"
                              );
                            }}
                          >
                            Suivant <i className="fal fa-long-arrow-right" />
                          </button>
                        </div>
                      </form>
                    ) : null}
                    {activeStep("/candidature/cv-lettre-de-motivation") ? (
                      <form>
                        <h4 className="mb-1">CV & Lettre de motivation</h4>
                        <div className="row mt-5">
                          <div className="col-lg-6">
                            <label htmlFor="cv">Votre CV</label>
                            <div className="contact-field p-relative c-name mb-30">
                              <input
                                type="file"
                                id="cv"
                                accept=".pdf, .jpg, .jpeg, .png"
                                onChange={(event) =>
                                  setCv(event.target.files[0])
                                }
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <label htmlFor="motivation">
                              Lettre de motivation
                            </label>
                            <div className="contact-field p-relative c-name mb-30 d-flex">
                              <input
                                type="file"
                                id="motivation"
                                accept=".pdf, .jpg, .jpeg, .png"
                                onChange={(event) =>
                                  setMotivation(event.target.files[0])
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="mt-2 d-flex justify-content-between">
                          <button
                            className="btn ss-btn"
                            data-animation="fadeInRight"
                            data-delay=".8s"
                            onClick={(e) => {
                              e.preventDefault();
                              changePage("/candidature/documents");
                            }}
                          >
                            <i className="fal fa-long-arrow-left" /> Précédent
                          </button>
                          <button
                            className="btn ss-btn"
                            data-animation="fadeInRight"
                            data-delay=".8s"
                            onClick={(e) => {
                              e.preventDefault();
                              savePayment();
                              saveAdmission();
                              changePage("/candidature/paiement");
                            }}
                          >
                            Suivant <i className="fal fa-long-arrow-right" />
                          </button>
                        </div>
                      </form>
                    ) : null}
                    {activeStep("/candidature/paiement") ? (
                      <main>
                        <h4 className="mb-1">Récapitulatif & paiement</h4>

                        <div className="row mb-5">
                          <div className="col-4">
                            <strong className="d-block text-primary mb-2">
                              Votre scolarité
                            </strong>

                            <div className="mt-2">
                              <div>
                                <strong>Dernier diplôme :</strong>
                                <span className="ml-1">{lastdiploma}</span>
                              </div>
                              <div className="mt-1">
                                <strong>Intitulé du diplôme :</strong>
                                <span className="ml-1">{course}</span>
                              </div>
                              <div className="mt-1">
                                <strong>Dernière classe suivie :</strong>
                                <span className="ml-1">{course}</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-4">
                            <strong className="d-block text-primary mb-2">
                              Votre candidature
                            </strong>
                            <div className="d-flex align-items-center">
                              {country === "ci" ? (
                                <>
                                  <Image name="CIFlag" />{" "}
                                  <span className="ml-1">Côte d'Ivoire</span>
                                </>
                              ) : country === "fr" ? (
                                <>
                                  <Image name="FRFlag" />{" "}
                                  <span className="ml-1">France</span>
                                </>
                              ) : (
                                <>
                                  <Image name="SNFlag" />{" "}
                                  <span className="ml-1">Sénégal</span>
                                </>
                              )}
                            </div>
                            <div className="mt-2">
                              {level === "b1" ? (
                                <>
                                  <strong>Bachelor 1 :</strong>
                                </>
                              ) : level === "b2" ? (
                                <>
                                  <strong>Bachelor 2 :</strong>
                                </>
                              ) : level === "b3" ? (
                                <>
                                  <strong>Bachelor 3 :</strong>
                                </>
                              ) : level === "m1" ? (
                                <>
                                  <strong>Master 1 :</strong>
                                </>
                              ) : (
                                <>
                                  <strong>Master 2 :</strong>
                                </>
                              )}
                              <span className="ml-1">{course}</span>
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="text-right">
                              <h3>Frais de pré-inscription</h3>
                              <h2>
                                <span className="text-primary">
                                  {country !== "fr" ? "100.000F CFA" : "153€"}
                                </span>{" "}
                                *
                              </h2>
                            </div>
                          </div>
                        </div>

                        {/*<Payment stripePromise={stripePromise} />*/}
                        <div className="mt-2 d-flex justify-content-between">
                          <button
                            className="btn ss-btn"
                            data-animation="fadeInRight"
                            data-delay=".8s"
                            onClick={(e) => {
                              e.preventDefault();
                              changePage(
                                "/candidature/cv-lettre-de-motivation"
                              );
                            }}
                          >
                            <i className="fal fa-long-arrow-left" /> Précédent
                          </button>
                          <div className="d-block">
                            <Elements stripe={stripePromise}>
                              <CheckoutButton />
                            </Elements>
                          </div>
                          {/*{country !== "fr" ? (
                                  <>
                                   <div className='d-block'>
                                      <button className='btn btn-success' onClick={(e) => {
                                        e.preventDefault();
                                        saveAdmission();
                                      }}>Payer <i className="fal fa-credit-card" /></button>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className='d-block'>
                                      <Elements stripe={stripePromise}>
                                        <CheckoutButton />
                                      </Elements>
                                    </div>
                                  </>
                                )}*/}
                        </div>
                        <i className="d-flex justify-content-end">
                          * Les frais de pré-inscription ne sont pas
                          remboursables.
                        </i>
                      </main>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Main;
