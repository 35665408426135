import React from 'react'
import Header from '../headerone/Main'
import Bredcom from '../Bredcom/Main'
import Form from '../menadmission/Form' 
import Image from "../Image/Main";

function Main() {
  return (
    <>
        <Header/>
        <Bredcom 
        subtitle="Diagnostic"/>
        <section className="about-area about-p pt-120 pb-120 p-relative fix">
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="s-about-img p-relative  wow fadeInLeft animated" data-animation="fadeInLeft" data-delay=".4s">
                  <Image name="ImageDiagnostic" classname="radius2em" />
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s">
                  <div className="about-title second-title pb-25">
                    <h5><i className="fal fa-graduation-cap" /> Diagnostic</h5>
                    <h3>Plus qu’un concours...</h3>
                  </div>
                  <p className="txt-clr">
                    <strong>DIAGNOSTIC</strong> est une opportunité unique de plonger au cœur des réalités stratégiques des problématiques d’entreprise. Vivre de l’intérieur l’expérience dynamique de la pratique des notions et compétences abordées en cours, d’acquérir et renforcer une méthodologie validée par des professionnels, ne se raconte pas. <br />
                    Participer à DIAGNOSTIC offre une vraie valeur ajoutée à votre CV, à votre expérience professionnelle. <br />
                    Au-delà des alternances et des stages pratiques, c’est une vraie ouverture sur votre futur Emploi.
                  </p>
                </div>
              </div>
            </div>
            <div className="row mt-40">
              <div className="col-lg-4 col-md-12">
                <p>
                  <h4>Un concours inter établissements</h4>
                  Organisé au sein du réseau d’établissements SBA par quatuor d’étudiants de même parcours.
                </p>
              </div>
              <div className="col-lg-4 col-md-12">
                <p>
                  <h4>Une vraie aventure d’équipe</h4>
                  4 étudiants coachés par un formateur organisent en toute autonomie leur travail autour des différentes phases de la compétition.
                </p>
              </div>
              <div className="col-lg-4 col-md-12">
                <p>
                  <h4>Une expérience essentielle</h4>
                  Une compétition organisée autour d’une problématique clé d’entreprise, selon le parcours d’étude des étudiants.
                </p>
              </div>
            </div>
          </div>
        </section>
        <div className="row center">
          <div className="col-lg-12 p-relative">
              <div className="section-title center-align mb-50 wow fadeInDown animated" data-animation="fadeInDown" data-delay=".4s" >
                  <h2>LES ÉTAPES DU CONCOURS</h2>
              </div>
          </div>
        </div>
        <section id="creativeLab" className="steps-area2 p-relative fix" style={{ backgroundColor: "#fff" }}>
          <div className="container">


            <div class="col-md-12 about-content2">
             <ul class="green2">                                              
              <li>
                <div class="abcontent">
                  <div>
                    <div class="ano">
                      <span>01</span>
                    </div>
                  </div> 
                  <div class="text">
                    <h3>Les présélections des équipes</h3> 
                    <p>La présélection est la première étape du concours. Il s’agit de la participation de différentes équipes sur un cas concret de type « d’étude de cas » composé de trois étapes prenant en compte l’Analyse, la stratégie et la mise e, œuvre de solutions. Les équipes doivent faire des propositions concrètes, choix qui feront l’objet d’un retour pédagogique et de sélection par le Comité de gestion des jeux.</p>
                  </div>
                </div>
              </li>
              <li>
                <div class="abcontent">
                  <div>
                    <div class="ano">
                      <span>02</span>
                    </div>
                  </div> 
                  <div class="text">
                    <h3>Les Deep Thoughts</h3> 
                    <p>C’est ici que les équipes de chaque parcours analyse les problématiques des entreprises partenaire exerçant dans leurs domaines d’études. C’est la deuxième étape de la compétition. Ils consistent en un travail en équipe d’analyse et d’élaboration d’un plan de développement selon les sujets, afin de répondre à une problématique réelle identifiée par l’entreprise partenaire. Il s’agit de la principale étape de DIAGNOSTIC.</p>
                  </div>
                </div>
              </li>
              <li>
                <div class="abcontent">
                  <div>
                    <div class="ano">
                      <span>03</span>
                    </div>
                  </div> 
                  <div class="text">
                    <h3>Les Inter Classes</h3> 
                    <p>La finale locale consiste en la soutenance contextualisée du plan de développement commercial rédigé en deux étapes (mise en situation professionnelle). Elle  est organisée au sein de chacune des écoles de chaque pays, en face-à-face, filmée, devant un jury composé de professionnels. Contrairement aux premières étapes, il s’agit d’une épreuve de pitching des solutions proposées dans un style « french conference ».</p>
                  </div>
                </div>
              </li>
              <li>
                <div class="abcontent">
                  <div>
                    <div class="ano">
                      <span>04</span>
                    </div> 
                  </div>
                  <div class="text">
                    <h3>Les Nationales</h3> 
                    <p>Les Nationales est la quatrième étape de la compétition, qui consiste en un choix des meilleurs quatuors par parcours après analyse des réponses écrites au cas partenaire et des prestations orales filmées. Il s’agit d’une étape en distanciel, dont l’évaluation se fait sur la base de supports préexistants. Les étudiants lauréats des finales locales son invités à affronter les lauréats des autres pays a la finale internationale.</p>
                  </div>
                </div>
              </li>
              <li>
                <div class="abcontent">
                  <div>
                    <div class="ano">
                      <span>05</span>
                    </div>
                  </div> 
                  <div class="text">
                    <h3>L’international</h3> 
                    <p>La finale internationale est la dernière étape du concours. Elle consiste en une présentation d’un plan d’action structuré et d’un entretien pour défendre chacune des étapes du plan lors d’un événement prestigieux dans un pays d’implantation de SBA. Contrairement aux finales locales, il s’agit d’épreuves orales et d’analyses des dossiers impliquant une interaction entre membres du quatuor et membres du jury autour de mises en situation professionnelles style « French Conference ».</p>
                  </div>
                </div>
              </li>
             </ul>
            </div>
          </div>
      </section>
    </>
  )
}

export default Main