import React from 'react'
import Bredcom from '../Bredcom/Main'
import Header from '../headerone/Main'
import First from '../mencourses/First'
import Brand from '../menhomethree/Brand'
import Courses from '../menhome/Courses'
import { Link } from 'react-router-dom'
import Image from '../Image/Main'


function Main() {
  return (
    <>
        <Header/>
        <Bredcom 
        subtitle="Bachelors"/>
        <div class="container">
          <h1 className="mt-50">Nos formations en Bachelor</h1>
          <div className="row mt-50">
            <div className="col-lg-4 col-md-6 ">
                <div className="courses-item mb-30 hover-zoomin">
                  <div className="thumb fix">
                      <Link to="/courses/bachelor/charge-de-rh-et-de-gestion-du-capital-humain">
                          <Image name="ImageCourseManagementBachelor" classname="radius2em" />
                      </Link>
                  </div>
                  <div className="courses-content">
                      <h3>
                      <Link to="/courses/bachelor/charge-de-rh-et-de-gestion-du-capital-humain">Chargé de RH et de gestion du capital humain</Link>
                      </h3>
                      <p>
                      Ce parcours forme les étudiants aux fondamentaux de la gestion du capital humain dans les organisations, les préparant à exercer plusieurs postes dans le domaine des ressources humaines.
                      </p>
                      <Link to="/courses/bachelor/charge-de-rh-et-de-gestion-du-capital-humain" className="readmore">
                      + d'infos <i className="fal fa-long-arrow-right" />
                      </Link>
                  </div>
                  <div className="icon">
                      <Image name="ImageCouIcon" />
                  </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 ">
                <div className="courses-item mb-30 hover-zoomin">
                  <div className="thumb fix">
                      <Link to="/courses/bachelor/charge-de-pilotage-des-unites-operationnelles">
                          <Image name="ImageCourseManagement" classname="radius2em" />
                      </Link>
                  </div>
                  <div className="courses-content">
                      <h3>
                      <Link to="/courses/bachelor/charge-de-pilotage-des-unites-operationnelles">Chargé de pilotage des unités opérationnelles</Link>
                      </h3>
                      <p>
                      Ce parcours prépare les étudiants à comprendre et acquérir les principaux leviers de gestion d’unités opérationnelles au sein d’une organisation.
                      </p>
                      <Link to="/courses/bachelor/charge-de-pilotage-des-unites-operationnelles" className="readmore">
                      + d'infos <i className="fal fa-long-arrow-right" />
                      </Link>
                  </div>
                  <div className="icon">
                      <Image name="ImageCouIcon" />
                  </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 ">
                <div className="courses-item mb-30 hover-zoomin">
                  <div className="thumb fix">
                      <Link to="/courses/bachelor/charge-de-commerce-marketing-et-communication">
                          <Image name="ImageCourseCommerce" classname="radius2em" />
                      </Link>
                  </div>
                  <div className="courses-content">
                      <h3>
                      <Link to="/courses/bachelor/charge-de-commerce-marketing-et-communication">Chargé de Commerce, Marketing et Communication</Link>
                      </h3>
                      <p>
                      Ce parcours a pour vocation de former des étudiants capables de concevoir et mettre en œuvre des actions commerciales et de marketing efficace.
                      </p>
                      <Link to="/courses/bachelor/charge-de-commerce-marketing-et-communication" className="readmore">
                      + d'infos <i className="fal fa-long-arrow-right" />
                      </Link>
                  </div>
                  <div className="icon">
                      <Image name="ImageCouIcon" />
                  </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 ">
                <div className="courses-item mb-30 hover-zoomin">
                  <div className="thumb fix">
                      <Link to="/courses/bachelor/responsable-qualite-et-qhse">
                          <Image name="ImageCourseQhse" classname="radius2em" />
                      </Link>
                  </div>
                  <div className="courses-content">
                      <h3>
                      <Link to="/courses/bachelor/responsable-qualite-et-qhse">Responsable Qualité et QHSE</Link>
                      </h3>
                      <p>
                      Ce parcours prépare les étudiants à relever les défis liés à la gestion de la qualité, de la sécurité, de la santé et de l’environnement au sein des organisations.
                      </p>
                      <Link to="/courses/bachelor/responsable-qualite-et-qhse" className="readmore">
                      + d'infos <i className="fal fa-long-arrow-right" />
                      </Link>
                  </div>
                  <div className="icon">
                      <Image name="ImageCouIcon" />
                  </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 ">
                <div className="courses-item mb-30 hover-zoomin">
                  <div className="thumb fix">
                      <Link to="/courses/bachelor/charge-des-operations-banque-assurance-fintech">
                          <Image name="ImageCourseBanque" classname="radius2em" />
                      </Link>
                  </div>
                  <div className="courses-content">
                      <h3>
                      <Link to="/courses/bachelor/charge-des-operations-banque-assurance-fintech">Chargé des opérations Banque, Assurance et Fintech</Link>
                      </h3>
                      <p>
                      Ce parcours prépare les étudiants à la compréhension et à l’intégration des évolutions rapides des secteurs financiers et technologiques.
                      </p>
                      <Link to="/courses/bachelor/charge-des-operations-banque-assurance-fintech" className="readmore">
                      + d'infos <i className="fal fa-long-arrow-right" />
                      </Link>
                  </div>
                  <div className="icon">
                      <Image name="ImageCouIcon" />
                  </div>
                </div>
            </div>
          </div>
        </div>
      <Brand/>
    </>
  )
}

export default Main