import React from 'react'
import { Link } from 'react-router-dom'
import Image from '../Image/Main'

function About() {
  return (
    <>
       <section className="about-area about-p pt-120 pb-120 p-relative fix" style={{ background: "#eff7ff" }} >
            <div className="container">
                <div className="row justify-content-center align-items-center">
                <div className="col-lg-6 col-md-12 col-sm-12">
                    <div className="s-about-img p-relative  wow fadeInLeft animated" data-animation="fadeInLeft" data-delay=".4s" >
                        <Image name="ImageEtudiantsDivers" className="radius2em" />
                        <div className="about-text second-about hide">
                            <span>
                            <sub>+</sub>17
                            </span>
                            <p>Années cumulées de pédagogie</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                    <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                    <div className="about-title second-title pb-25">
                        <h5>
                        <i className="fal fa-graduation-cap" /> Notre école
                        </h5>
                        <h2>Quelques mots...</h2>
                    </div>
                    <p className="txt-clr">
                        SKILLS BRIDGE ACADEMY est une école de commerce et de management spécialisée dans la formation des managers et
                        cadres de direction de demain. <br />
                        Conçus sur la base des référentiels et syllabus de certificateurs français des
                        centres de formation et d’apprentissage, nos cours confèrent les compétences requises par les meilleurs professionnels
                        européens et internationaux.
                    </p>
                    <div className="about-content2 hide">
                        <div className="row">
                        <div className="col-md-12">
                            <ul className="green2">
                            <li>
                                <div className="abcontent">
                                    <div>
                                        <div className="ano"><span>01</span></div>
                                    </div>
                                    <div className="text">
                                        <h3>Creative Lab &amp; Diagnostic</h3>
                                        <p>L’entreprenariat est aujourd’hui la clé de l’innovation des modèles économiques qui animent nos marchés...
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="abcontent">
                                    <div>
                                        <div className="ano"><span>02</span></div>
                                    </div>
                                    <div className="text">
                                        <h3>Mobilité internationale</h3>
                                        <p>Notre programme de mobilité internationale s’articule autour de deux axes : la récompenses des meilleurs de nos étudiants sur chaque parcours...</p>
                                    </div>
                                </div>
                            </li>
                            </ul>
                        </div>
                        </div>
                    </div>
                    <div className="slider-btn mt-20">
                        <Link to="/about" className="btn ss-btn smoth-scroll">
                        En savoir plus <i className="fal fa-long-arrow-right" />
                        </Link>
                    </div>
                    </div>
                </div>
                </div>
            </div>
       </section>
    </>
  )
}

export default About