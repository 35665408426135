import React, { useState } from "react"
import { Link } from "react-router-dom"
import { useLocation } from "react-router-dom"
import Image from "../Image/Main"

function Main() {
  const [Btnshow, setBtnshow] = useState(false);
  const [mobile, setmobile] = useState(false);
  const [Ecole, setEcole] = useState(false);
  const [Bachelors, setBachelors] = useState(false);
  const [Masters, setMasters] = useState(false);
  const [Admission, setAdmission] = useState(false);
  const location = useLocation();
  const getLinkClass = (path) => {
    return (
      (path === location.pathname ? "active" : undefined)
    );
  };

  return (
    <>
      <header className="header-area header-three">
        <div className="header-top second-header d-none">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-4 col-md-4 d-none d-lg-block ">
                <div className="header-social">
                  <span>
                    Suivez-nous
                    <Link to="#" title="Facebook">
                      <i className="fab fa-facebook-f" />
                    </Link>
                    <Link to="#" title="LinkedIn">
                      <i className="fab fa-instagram" />
                    </Link>
                    <Link to="#" title="Twitter">
                      <i className="fab fa-twitter" />
                    </Link>
                    <Link to="#" title="Twitter">
                      <i className="fab fa-youtube" />
                    </Link>
                  </span>
                </div>
              </div>
              <div className="col-lg-8 col-md-8 d-none d-lg-block text-right">
                <div className="header-cta">
                  <ul>
                    <li>
                      <div className="call-box">
                        <div className="icon">
                          <Image name="ImagePhoneCall" />
                        </div>
                        <div className="text">
                          <span>Call Now !</span>
                          <strong>
                            <Link to="tel:+917052101786">+91 7052 101 786</Link>
                          </strong>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="call-box">
                        <div className="icon">
                          <Image name="ImageMailing" />
                        </div>
                        <div className="text">
                          <span>Email Now</span>
                          <strong>
                            <Link to="mailto:info@example.com">
                              {" "}
                              info@example.com
                            </Link>
                          </strong>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="header-sticky" className="menu-area">
          <div className="container">
            <div className="second-menu">
              <div className="row align-items-center">
                <div className="col-xl-3 col-lg-3">
                  <div className="logo w20">
                    <Link to="/"><Image /> Skills Bridge Academy</Link>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6">
                  <div className="main-menu text-right text-xl-right">
                    <nav id="mobile-menu">
                      <ul>
                        <li><Link to="/" className={getLinkClass("/")}>Accueil</Link></li>
                        <li className="has-sub">
                          <Link to="/about" className={getLinkClass("/about") || getLinkClass("/about/creative-lab") || getLinkClass("/about/diagnostic") || getLinkClass("/prosfibe")}>
                            L'école
                            <i class="fa fa-angle-down"></i>
                          </Link>
                          <ul>
                            <li><Link to="/about" className={getLinkClass("/about")}>Skills Bridge Academy</Link></li>
                            {/*<li><Link to="/about/creative-lab" className={getLinkClass("/about/creative-lab")}>Creative Lab</Link></li>*/}
                            <li><Link to="/about/diagnostic" className={getLinkClass("/about/diagnostic")}>Diagnostic</Link></li>
                            <li><Link to="/prosfibe" className={getLinkClass("/prosfibe")}>Programme de bourses</Link></li>
                          </ul>
                        </li>
                        <li className="has-sub">
                          <Link to="/courses/bachelor" className={getLinkClass("/courses/bachelor") || getLinkClass("/courses/bachelor/charge-de-rh-et-de-gestion-du-capital-humain") || getLinkClass("/courses/bachelor/charge-de-pilotage-des-unites-operationnelles") || getLinkClass("/courses/bachelor/charge-de-commerce-marketing-et-communication") || getLinkClass("/courses/bachelor/responsable-qualite-et-qhse") || getLinkClass("/courses/bachelor/charge-des-operations-banque-assurance-fintech")}>
                            Bachelors
                            <i class="fa fa-angle-down"></i>
                          </Link>
                          <ul>
                            <li><Link to="/courses/bachelor/charge-de-rh-et-de-gestion-du-capital-humain" className={getLinkClass("/courses/bachelor/charge-de-rh-et-de-gestion-du-capital-humain")}>Chargé de RH et de gestion du Capital Humain</Link></li>
                            <li><Link to="/courses/bachelor/charge-de-pilotage-des-unites-operationnelles" className={getLinkClass("/courses/bachelor/charge-de-pilotage-des-unites-operationnelles")}>Chargé de pilotage des Unités Opérationnelles</Link></li>
                            <li><Link to="/courses/bachelor/charge-de-commerce-marketing-et-communication" className={getLinkClass("/courses/bachelor/charge-de-commerce-marketing-et-communication")}>Chargé de Commerce, Marketing et Communication</Link></li>
                            <li><Link to="/courses/bachelor/responsable-qualite-et-qhse" className={getLinkClass("/courses/bachelor/responsable-qualite-et-qhse")}>Responsable Qualité et QHSE</Link></li>
                            <li><Link to="/courses/bachelor/charge-des-operations-banque-assurance-fintech" className={getLinkClass("/courses/bachelor/charge-des-operations-banque-assurance-fintech")}>Chargé des opérations Banque, Assurance et Fintech</Link></li>
                          </ul>
                        </li>
                        <li className="has-sub">
                          <Link to="/courses/master" className={getLinkClass("/courses/master") || getLinkClass("/courses/master/rh-et-management-du-capital-humain") || getLinkClass("/courses/master/management-des-organisations-et-des-unites-operationnelles") || getLinkClass("/courses/master/commerce-marketing-et-communication") || getLinkClass("/courses/master/management-de-la-qualite-qhse") || getLinkClass("/courses/master/banque-assurance-et-fintech")}>
                            Masters
                            <i class="fa fa-angle-down"></i>
                          </Link>
                          <ul>
                            <li><Link to="/courses/master/rh-et-management-du-capital-humain" className={getLinkClass("/courses/master/rh-et-management-du-capital-humain")}>RH et Management du capital humain</Link></li>
                            <li><Link to="/courses/master/management-des-organisations-et-des-unites-operationnelles" className={getLinkClass("/courses/master/management-des-organisations-et-des-unites-operationnelles")}>Management des Organisations et des unités opérationnelles</Link></li>
                            <li><Link to="/courses/master/commerce-marketing-et-communication" className={getLinkClass("/courses/master/commerce-marketing-et-communication")}>Commerce Marketing et Communication</Link></li>
                            <li><Link to="/courses/master/management-de-la-qualite-qhse" className={getLinkClass("/courses/master/management-de-la-qualite-qhse")}>Management de la qualité QHSE</Link></li>
                            <li><Link to="/courses/master/banque-assurance-et-fintech" className={getLinkClass("/courses/master/banque-assurance-et-fintech")}>Banque Assurance et Fintech</Link></li>
                          </ul>
                        </li>
                        {/*<li><Link to="/candidature">Candidature</Link></li>*/}
                        <li className="has-sub">
                          <Link to="#" className={getLinkClass("/admission/procedure") || getLinkClass("/admission/dossier") || getLinkClass("/candidature") || getLinkClass("/formations/tarifs")}>
                            Admission
                            <i class="fa fa-angle-down"></i>
                          </Link>
                          <ul>
                            {/*<li><Link to="/admission/procedure">Comment intégrer SBA</Link></li>
                            <li><Link to="/admission/dossier" className={getLinkClass("/admission/dossier")}>Dossier de candidature</Link></li>*/}
                            <li><Link to="/candidature" className={getLinkClass("/candidature")}>Candidature</Link></li>
                            <li><Link to="/formations/tarifs" className={getLinkClass("/formations/tarifs")}>Tarifs des formations</Link></li>
                          </ul>
                        </li>
                        <li><Link to="/alternance" className={getLinkClass("/alternance")}>Alternance</Link></li>
                        <li><Link to="/contact" className={getLinkClass("/contact")}>Contact</Link></li>
                      </ul>
                    </nav>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 text-right d-none d-lg-block text-right text-xl-right">
                  <div className="login">
                    <ul>
                      {/*<li>
                        <Link
                          to="#"
                          className="menu-tigger"
                          onClick={() => {
                            setBtnshow(true);
                          }}
                        >
                          <i
                            className="fal fa-search"
                            onClick={() => {
                              setBtnshow(true);
                            }}
                          />
                        </Link>
                      </li>
                      <li>
                        <div className="second-header-btn">
                          <Link to="https://school.skillsbridge-academy.com/" className="btn">
                            Se connecter
                          </Link>
                        </div>
                      </li>*/}
                    </ul>
                  </div>
                </div>
                <div className="col-12">
                  <div className="mobile-menu mean-container">
                    <div className="mean-bar">
                      <a
                        href="#nav"
                        onClick={() => setmobile(!mobile)}
                        className={`meanmenu-reveal ${mobile && "meanclose"}`}
                        style={{
                          right: 0,
                          left: "auto",
                          textAlign: "center",
                          textIndent: 0,
                          fontSize: 18,
                        }}
                      >
                        {mobile ? (
                          "X"
                        ) : (
                          <span>
                            <span>
                              <span></span>
                            </span>
                          </span>
                        )}
                      </a>
                      {mobile && (
                        <nav className="mean-nav">
                          <ul style={{ display: "block" }}>
                            <li><Link to="/">Accueil</Link></li>
                            <li className="has-sub">
                              <a href="/about">L'école</a>
                              {Ecole && (
                                <ul style={{ display: "block" }}>
                                  <li><Link to="/about">Skills Bridge Academy</Link></li>
                                  <li><Link to="/about/creative-lab">Creative Lab</Link></li>
                                  <li><Link to="/about/diagnostic">Diagnostic</Link></li>
                                  <li><Link to="/prosfibe">Programme de bourses</Link></li>
                                </ul>
                              )}
                              <a
                                className={`mean-expand ${
                                  mobile && "mean-clicked"
                                }`}
                                onClick={() => {
                                  setEcole(!Ecole);
                                }}
                                href="#"
                                style={{ fontSize: 18 }}
                              >
                                {Ecole ? "-" : "+"}
                              </a>
                            </li>
                            <li className="has-sub">
                              <Link to="/courses/bachelor">Bachelors</Link>
                              {Bachelors && (
                                <ul style={{ display: "block" }}>
                                  <li><Link to="/courses/bachelor/charge-de-rh-et-de-gestion-du-capital-humain">Bachelor 1</Link></li>
                                  <li><Link to="/courses/bachelor/charge-de-rh-et-de-gestion-du-capital-humain">Chargé de RH et de gestion du Capital Humain</Link></li>
                                  <li><Link to="/courses/bachelor/charge-de-pilotage-des-unites-operationnelles">Chargé de pilotage des Unités Opérationnelles</Link></li>
                                  <li><Link to="/courses/bachelor/charge-de-commerce-marketing-et-communication">Chargé de Commerce, Marketing et Communication</Link></li>
                                  <li><Link to="/courses/bachelor/responsable-qualite-et-qhse">Responsable Qualité et QHSE</Link></li>
                                  <li><Link to="/courses/bachelor/charge-des-operations-banque-assurance-fintech">Chargé des opérations Banque, Assurance et Fintech</Link></li>
                                </ul>
                              )}

                              <a
                                className={`mean-expand ${
                                  mobile && "mean-clicked"
                                }`}
                                onClick={() => {
                                  setBachelors(!Bachelors);
                                }}
                                href="#"
                                style={{ fontSize: 18 }}
                              >
                                {Bachelors ? "-" : "+"}
                              </a>
                            </li>
                            <li className="has-sub">
                              <a href="/courses/master">Masters</a>
                              {Masters && (
                                <ul style={{ display: "block" }}>
                                  <li><Link to="/courses/master/rh-et-management-du-capital-humain">RH et Management du capital humain</Link></li>
                                  <li><Link to="/courses/master/management-des-organisations-et-des-unites-operationnelles">Management des Organisation et des unités opérationnelles</Link></li>
                                  <li><Link to="/courses/master/commerce-marketing-et-communication">Commerce Marketing et Communication</Link></li>
                                  <li><Link to="/courses/master/management-de-la-qualite-qhse">Management de la qualité QHSE</Link></li>
                                  <li><Link to="/courses/master/banque-assurance-et-fintech">Banque Assurance et Fintech</Link></li>
                                </ul>
                              )}
                              <a
                                className={`mean-expand ${
                                  mobile && "mean-clicked"
                                }`}
                                onClick={() => {
                                  setMasters(!Masters);
                                }}
                                style={{ fontSize: 18 }}
                              >
                                {Masters ? "-" : "+"}
                              </a>
                            </li>
                            <li className="has-sub">
                              <Link to="#">Admission</Link>
                              {Admission && (
                                <ul style={{ display: "block" }}>
                                  <li><Link to="#">Comment intégrer SBA</Link></li>
                                  <li><Link to="/adminssion/dossier">Dossier de candidature</Link></li>
                                  <li><Link to="/candidature">Candidature</Link></li>
                                  <li><Link to="/formations/tarifs">Tarifs des formations</Link></li>
                                </ul>
                              )}
                              <a
                                className={`mean-expand ${
                                  mobile && "mean-clicked"
                                }`}
                                onClick={() => {
                                  setAdmission(!Admission);
                                }}
                                style={{ fontSize: 18 }}
                              >
                                {Admission ? "-" : "+"}
                              </a>
                            </li>
                            <li className="mean-last"><Link to="/alternance">Alternance</Link></li>
                            <li className="mean-last"><Link to="/contact">Contact</Link></li>
                          </ul>
                        </nav>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      {Btnshow && (
        <>
          <div className={`offcanvas-menu ${"active"}`}>
            <span className="menu-close" onClick={() => setBtnshow(false)}>
              <i className="fas fa-times" onClick={() => setBtnshow(false)} />
            </span>
            <form
              role="search"
              method="get"
              id="searchform"
              className="searchform"
              action="http://wordpress.zcube.in/xconsulta/"
            >
              <input type="text" name="s" id="search" placeholder="Search" />
              <button>
                <i className="fa fa-search" />
              </button>
            </form>
            <div id="cssmenu3" className="menu-one-page-menu-container">
              <ul className="menu">
                <li className="menu-item menu-item-type-custom menu-item-object-custom">
                  <Link to="/">Home</Link>
                </li>
                <li className="menu-item menu-item-type-custom menu-item-object-custom">
                  <Link to="/about">About Us</Link>
                </li>
                <li className="menu-item menu-item-type-custom menu-item-object-custom">
                  <Link to="/courses">Courses</Link>
                </li>
                <li className="menu-item menu-item-type-custom menu-item-object-custom">
                  <Link to="/pricing">Pricing </Link>
                </li>
                <li className="menu-item menu-item-type-custom menu-item-object-custom">
                  <Link to="/team">Teacher</Link>
                </li>
                <li className="menu-item menu-item-type-custom menu-item-object-custom">
                  <Link to="/projects">Gallery</Link>
                </li>
                <li className="menu-item menu-item-type-custom menu-item-object-custom">
                  <Link to="/blog">Blog</Link>
                </li>
                <li className="menu-item menu-item-type-custom menu-item-object-custom">
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>
            </div>
            <div id="cssmenu2" className="menu-one-page-menu-container">
              <ul id="menu-one-page-menu-12" className="menu">
                <li className="menu-item menu-item-type-custom menu-item-object-custom">
                  <Link to="#home">
                    <span>+8 12 3456897</span>
                  </Link>
                </li>
                <li className="menu-item menu-item-type-custom menu-item-object-custom">
                  <Link to="#howitwork">
                    <span>info@example.com</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className={`offcanvas-overly ${"active"}`}> </div>
        </>
      )}
    </>
  );
}

export default Main;
