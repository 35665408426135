import React from 'react'
import Bredcom from '../Bredcom/Main'
import Header from '../headerone/Main'
import BachelorRH from '../mencoursedetails/BachelorRH'
import Courses from '../menhome/Courses'
import Brand from '../menhomethree/Brand'

function Main() {
  return (
    <>
        <Header/>
        <Bredcom 
          title="Bachelors" 
          titleLink="/courses/bachelors" 
          subtitle="Bachelor - Chargé de RH et de gestion du capital humain" />
        <BachelorRH/>
        <Courses/>
    </>
  )
}

export default Main