import React from "react";
import { Link } from "react-router-dom";
import Image from '../Image/Main'

function Main() {
  return (
    <>
      <footer
        className="footer-bg footer-p pt-90 text-white"
        style={{
          backgroundColor: "#0079e8",
        }}
      >
        <div className="footer-top pb-70">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-xl-4 col-lg-4 col-sm-6">
                <div className="footer-widget mb-30">
                  <div className="f-widget-title">
                    
                    <h2>Skills Bridge Academy</h2>
                     
                  </div>
                  <Image name="LogoBlanc" className="logo" />
                  <div className="f-contact text-white mt-10">
                    <p>
                      Skills Bridge Academy est une école de commerce et de management spécialisée dans la formation des managers et cadres de direction de demain.
                      <br /><br />
                      ©2024 Skills Bridge Academy
                    </p>
                  </div>
                  <div className="footer-social mt-10">
                    <a href="https://www.facebook.com/skillsBridgeAcademy" target="_blank">
                      <i className="fab fa-facebook-f" />
                    </a>
                    <a href="https://www.linkedin.com/company/skills-bridge-academy">
                      <i className="fab fa-linkedin" />
                    </a>
                  </div>

                  {/**
                     <div className="footer-link mt-4">
                    <ul>
                      <li>
                        <Link to="/">©2024 Skills Bridge Academy</Link>
                      </li>
                    </ul>
                  </div>
                     */}
                </div>
              </div>
              <div className="col-xl-2 col-lg-2 col-sm-6">
                <div className="footer-widget mb-30">
                  <div className="f-widget-title">
                    <h2>À propos</h2>
                  </div>
                  <div className="footer-link">
                    <ul>
                      <li>
                        <Link to="/about"> L'école</Link>
                      </li>
                      <li>
                        <Link to="/courses">Creative Lab</Link>
                      </li>
                      <li>
                        <Link to="/contact"> Diagnostic</Link>
                      </li>
                      <li>
                        <Link to="/prosfibe">Programme de Bourses </Link>
                      </li>
                      <li>
                        <Link to="/alternance">Alternance </Link>
                      </li>
                      <li>
                        <Link to="/contact">Contact </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-sm-6">
                <div className="footer-widget mb-30">
                  <div className="f-widget-title">
                    <h2>Bachelor</h2>
                  </div>
                  <div className="footer-link">
                    <ul>
                      <li>
                        <Link to="/courses/bachelor/charge-de-rh-et-de-gestion-du-capital-humain">Chargé de RH et de gestion du Capital Humain</Link>
                      </li>
                      <li>
                        <Link to="/courses/bachelor/charge-de-pilotage-des-unites-operationnelles">Chargé de pilotage des Unités Opérationnelles</Link>
                      </li>
                      <li>
                        <Link to="/courses/bachelor/charge-de-commerce-marketing-et-communication">Chargé de Commerce, Marketing et Communication</Link>
                      </li>
                      <li>
                        <Link to="/courses/bachelor/responsable-qualite-et-qhse">Responsable Qualité et QHSE</Link>
                      </li>
                      <li>
                        <Link to="/courses/bachelor/charge-des-operations-banque-assurance-fintech">Chargé des opérations Banque, Assurance et Fintech</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-sm-6">
                <div className="footer-widget mb-30">
                  <div className="f-widget-title">
                    <h2>Master 1 & 2</h2>
                  </div>
                  <div className="footer-link">
                    <ul>
                      <li>
                        <Link to="/courses/master/rh-et-management-du-capital-humain">RH et Management du capital humain</Link>
                      </li>
                      <li>
                        <Link to="/courses/master/management-des-organisations-et-des-unites-operationnelles">Management des Organisation et des unités opérationnelles</Link>
                      </li>
                      <li>
                        <Link to="/courses/master/commerce-marketing-et-communication">Commerce Marketing et Communication</Link>
                      </li>
                      <li>
                        <Link to="/courses/master/management-de-la-qualite-qhse">Management de la qualité QHSE</Link>
                      </li>
                      <li>
                        <Link to="/courses/master/banque-assurance-et-fintech">Banque Assurance et Fintech</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/**
                 <div className="col-xl-3 col-lg-3 col-sm-6">
                <div className="footer-widget mb-30">
                  <div className="f-widget-title">
                   <h2>Contact Us</h2>
                  </div>
                  <div className="f-contact">
                    <ul>
                      <li>
                        <i className="icon fal fa-phone" />
                        <span>
                          <Link to="tel:+14440008888">+1 (444) 000-8888</Link>
                          <br />
                          <Link to="tel:+917052101786">+91 7052 101 786</Link>
                        </span>
                      </li>
                      <li>
                        <i className="icon fal fa-envelope" />
                        <span>
                          <Link to="mailto:info@example.com">
                            info@example.com
                          </Link>
                          <br />
                          <Link to="mailto:help@example.com">
                            help@example.com
                          </Link>
                        </span>
                      </li>
                      <li>
                        <i className="icon fal fa-map-marker-check" />
                        <span>
                          1247/Plot No. 39, 15th Phase,
                          <br /> LHB Colony, Kanpur
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
                 */}
            </div>
          </div>
        </div>
        {/**
        <div className="copyright-wrap">
          <div className="container">
            <div className="row align-items-center">
              
              <div className="col-lg-4">
                <div className="copy-text">
                  <Link to="/">
                    <Image name="LogoFooter" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 text-center"></div>
              <div className="col-lg-4 text-right text-xl-right">
                Copyright © SBA 2023 . All rights reserved.
              </div>
              <div className="col-lg-12 text-center"></div>
              
            </div>
          </div>
        </div>*/}
      </footer>
    </>
  );
}

export default Main;
