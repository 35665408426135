import React from 'react'
import Image from '../Image/Main'

function Shep() {
  return (
    <>
        <section className="steps-area p-relative" style={{ backgroundColor: "#032e3f" }} >
            <div className="animations-10">
                <Image name="ImageBg10" />
            </div>
            <div className="container">
                <div className="row align-items-center">
                <div className="col-lg-6 col-md-12">
                    <div className="section-title mb-35 wow fadeInDown animated" data-animation="fadeInDown" data-delay=".4s" >
                    <h2>Nos valeurs</h2>
                    <p>
                        Nous mettons un point d’honneur à offrir une formation de qualité, en intégrant des méthodes pédagogiques innovantes et adaptées aux défis actuels. Nos formateurs, experts dans leurs domaines, assurent une transmission de compétences pratiques et directement applicables.
                    </p>
                    </div>
                    <ul className="pr-20">
                    <li>
                        <div className="step-box wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s" >
                        <div className="dnumber">
                            <div className="date-box">
                                <Image name="ImageFeaIcon03" />
                            </div>
                        </div>
                        <div className="text">
                            <h3>Excellence et Innovation Pédagogique</h3>
                            <p>
                            Nous offrons une formation innovante avec des experts, adaptée aux dernières avancées technologiques et managériales.
                            </p>
                        </div>
                        </div>
                    </li>
                    <li>
                        <div className="step-box wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s" >
                        <div className="dnumber">
                            <div className="date-box">
                                <Image name="ImageFeaIcon01" />
                            </div>
                        </div>
                        <div className="text">
                            <h3>Adaptabilité, Diversité et Inclusion</h3>
                            <p>
                            Notre CFA, fondé par des entrepreneurs africains, valorise la diversité et forme des apprenants adaptables et performants.
                            </p>
                        </div>
                        </div>
                    </li>
                    <li>
                        <div className="step-box wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s" >
                        <div className="dnumber">
                            <div className="date-box">
                                <Image name="ImageFeaIcon02" />
                            </div>
                        </div>
                        <div className="text">
                            <h3>Leadership et Responsabilité Sociale</h3>
                            <p>
                            SBA forme des professionnels stratégiques, responsables et éthiques, avec des compétences en leadership et gestion de projets.
                            </p>
                        </div>
                        </div>
                    </li>
                    </ul>
                </div>
                <div className="col-lg-6 col-md-12">
                    <div className="step-img wow fadeInLeft animated" data-animation="fadeInLeft" data-delay=".4s" >
                        <Image name="ImageValeurs" />
                    </div>
                </div>
                </div>
            </div>
        </section>

    </>
  )
}

export default Shep