import React from 'react'
import { Link } from 'react-router-dom'
import ImageCtaBg02 from '../../assets/img/bg/cta_bg02.png'

function Scholarship() {
  return (
    <>
        <section id="prosfibe" className="cta-area cta-bg pt-50 pb-50" style={{ backgroundColor: "#3d77b7" }} >
            <div className="container">
                <div className="row justify-content-center">
                <div className="col-lg-8">
                    <div className="section-title cta-title wow fadeInLeft animated" data-animation="fadeInDown animated" data-delay=".2s" >
                    <h2>Programme PROSFIBE</h2>
                    <p>
                        Le Programme Social de Financement de Bourses d’Etudes, souligne notre engagement Social et durable (RSE) en faveur des étudiants ayant obtenu des meilleurs résultats au baccalauréat, ayant été affectés dans une université, mais issus de familles modestes en incapacité partielle ou totale de prendre en charge les études supérieures de leurs enfants.
                    </p>
                    </div>
                </div>
                <div className="col-lg-4 text-right">
                    <div className="cta-btn s-cta-btn wow fadeInRight animated mt-30" data-animation="fadeInDown animated" data-delay=".2s" >
                    <Link to="/prosfibe" className="btn ss-btn smoth-scroll">
                        Découvrir le programme <i className="fal fa-long-arrow-right" />
                    </Link>
                    </div>
                </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default Scholarship