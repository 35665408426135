import React, { useState } from "react";
import SocialLogin from "./SocialLogin";
function LoginForm() {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [keepOnline, setKeepOnline] = useState(false);

  return (
    <>
      <section
        id="login"
        className="contact-area after-none contact-bg pt-120 pb-120 p-relative fix"
        style={{ background: "#e7f0f8" }}
      >
        <div className="container authentication login">
          <div className="row">
            <div className="col-lg-12 order-2">
              <div className="contact-bg">
                <div className="section-title center-align text-center mb-50">
                  <h4>Connexion à votre compte</h4>
                </div>
                <SocialLogin separator />
                <form
                  action=""
                  method="post"
                  className="contact-form mt-50 text-center"
                >
                  <div className="row">
                    <div className="col-lg-4 offset-lg-4">
                      <div className="col-lg-12">
                        <div className="contact-field p-relative c-subject mb-10">
                          <input
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Email"
                            required
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="contact-field p-relative c-subject mb-30">
                          <input
                            type={showPassword ? "text" : "password"}
                            id="password"
                            name="password"
                            placeholder="Mot de passe"
                            required
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          <i
                            className={
                              password !== ""
                                ? showPassword
                                  ? "icon fal fa-eye-slash pointer"
                                  : "icon fal fa-eye pointer"
                                : undefined
                            }
                            onClick={() => {
                              setShowPassword(!showPassword);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="row mb-30">
                          <div className="col-lg-6 col-sm-12">
                            <div className="contact-field form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="keepalive"
                                name="keepalive"
                                onChange={(e) => setKeepOnline(e.target.value)}
                              />
                              <label
                                id="keepalivelabel"
                                className="text-normal text-left form-check-label pointer"
                                htmlFor="keepalive"
                              >
                                Se souvenir de moi
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-6 col-sm-12">
                            <a
                              className="text-right"
                              id="pwdreset"
                              href="/password-reset"
                            >
                              Mot de passe oublié
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="slider-btn text-center">
                        <button
                          className="btn ss-btn"
                          data-animation="fadeInRight"
                          data-delay=".8s"
                        >
                          Se connecter <i className="fal fa-sign-in" />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="center-align text-center text-bold mt-50">
                Vous n'avez pas de compte ?{" "}
                <a href="/register">Inscrivez-vous</a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default LoginForm;
