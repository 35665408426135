import React, { useState } from "react";
import PasswordStrengthMeter from "./PasswordStrengthMeter";
function RegisterForm() {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  return (
    <>
      <section
        id="register"
        className="contact-area after-none contact-bg pt-120 pb-120 p-relative fix"
        style={{ background: "#e7f0f8" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 order-2">
              <div className="contact-bg">
                <div className="section-title center-align text-center mb-50">
                  <h4>Inscrivez-vous et commencez votre formation</h4>
                </div>
                <form
                  action=""
                  method="post"
                  className="contact-form mt-30 text-center"
                >
                  <div className="row">
                    <div className="col-lg-4 offset-lg-4">
                      <div className="row">
                        <div className="col-lg-7">
                          <div className="contact-field p-relative c-name mb-30">
                            <input
                              type="text"
                              id="firstname"
                              name="firstname"
                              placeholder="Prenoms"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-lg-5">
                          <div className="contact-field p-relative c-name mb-30">
                            <input
                              type="text"
                              id="lastname"
                              name="lastname"
                              placeholder="Nom"
                              required
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="contact-field p-relative c-subject mb-30">
                          <input
                            type="text"
                            id="email"
                            name="email"
                            placeholder="Email"
                            required
                          />
                          <i className="icon fal fa-envelope" />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="contact-field p-relative c-subject mb-10">
                          <input
                            type={showPassword ? "text" : "password"}
                            id="password"
                            name="password"
                            placeholder="Mot de passe"
                            required
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          <i
                            className={
                              password !== ""
                                ? showPassword
                                  ? "icon fal fa-eye-slash pointer"
                                  : "icon fal fa-eye pointer"
                                : undefined
                            }
                            onClick={() => {
                              setShowPassword(!showPassword);
                            }}
                          />
                        </div>
                      </div>
                      <PasswordStrengthMeter password={password} />
                      <div className="col-lg-12">
                        <div className="contact-field form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="keepalive"
                            name="keepalive"
                            onChange={(e) => console.log(e.target.value)}
                          />
                          <label
                            id="keepalivelabel"
                            className="text-normal text-left form-check-label pointer"
                            htmlFor="keepalive"
                          >
                            Je souhaite recevoir des offres spéciales et des
                            conseils d'apprentissage.
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12 mt-30">
                      <div className="slider-btn text-center">
                        <button
                          className="btn ss-btn"
                          data-animation="fadeInRight"
                          data-delay=".8s"
                        >
                          c'est parti <i className="fal fa-rocket" />
                        </button>
                      </div>
                    </div>

                    <div className="col-lg-12 mt-30">
                      En vous inscrivant, vous acceptez{" "}
                      <a href="/conditions-d-utilisation" className="underline">
                        nos conditions d'utilisation
                      </a>
                      <br />
                      et notre{" "}
                      <a
                        href="/politique-de-confidentialite"
                        className="underline"
                      >
                        politique de confidentialité
                      </a>
                      .
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default RegisterForm;
