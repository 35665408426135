import React from 'react'
import { Link } from 'react-router-dom'
import ImageBgAdmission from '../../assets/img/sba/etudiante.jpg'

function Admission() {
  return (
    <>
        <section className="about-area about-p pt-120 pb-120 p-relative fix">
            <div className="container">
                <div className="row justify-content-center align-items-center">
                <div className="col-lg-6 col-md-12 col-sm-12">
                    <div className="s-about-img p-relative  wow fadeInLeft animated" data-animation="fadeInLeft" data-delay=".4s" >
                        <img src={ImageBgAdmission} alt="img" className="radius2em" />
                    </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                    <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                    <div className="about-title second-title pb-25">
                        <h2>Admission</h2>
                    </div>
                    <p className="txt-clr">
                        Intégrez notre école de management en ligne, où l'excellence académique rencontre la flexibilité du digital. Nos programmes, du bachelor au master, sont conçus pour former les leaders de demain, en combinant théorie solide et pratiques concrètes du monde des affaires. Grâce à une plateforme innovante et des enseignants experts dans leur domaine, nous vous préparons à relever les défis de la gestion moderne.
                    </p>
                    <p className="txt-clr">
                        Rejoignez une communauté dynamique d'étudiants ambitieux et de professionnels du monde entier. Que vous souhaitiez débuter votre carrière ou progresser vers des postes de direction, nos cursus vous offrent les compétences clés pour réussir dans un environnement globalisé et en constante évolution.
                    </p>
                    <div className="slider-btn mt-20">
                        <Link to="/candidature" className="btn ss-btn smoth-scroll">
                        S'inscrire <i className="fal fa-long-arrow-right" />
                        </Link>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </section>

    </>
  )
}

export default Admission